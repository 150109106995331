import React from 'react';
import styled from 'styled-components';
import  Footer  from '../../components/footer';
import  Navbar  from '../../components/navbar';
import PageContainer ,{
    InnerPageContainer,
    
} from '../../components/pageContainer';
import { deviceSize } from '../../components/responsive';

import everywhere from '../../images/Hizmet/everywhere.jpg';
import lookTelphone from '../../images/Hizmet/lookTelphone.jpg';
import relax from '../../images/Hizmet/relax.jpg';
import saveTime from '../../images/Hizmet/saveTime.jpg';

import Tevkifat from '../../images/Hakkimda/Tevkifat.png';
import Earsiv from '../../images/Hakkimda/Earsiv.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import "./hakkimda.css";

import { useMediaQuery } from 'react-responsive';

const ContentContainer = styled.div`
    width: 100%;
    // max-width: ${deviceSize.laptop}px;
    display: flex;
    flex-direction: column;
    //  align-items: flex-start;
    padding: 1em;
    background-color: aliceblue;

    @media screen and (max-width: ${deviceSize.mobile}px) {
        padding: 5px;
    }
`;

export default function Hakkimda(props) {
    const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });

    return (
        <PageContainer>
            <Navbar />
            <InnerPageContainer>
                <ContentContainer>
                    <section className="hakkimda">
                        <motion.div
                            whileInView={{
                                x: 0,
                                rotate: 0,
                                opacity: 1,
                                y: 0,
                            }}
                            initial={false}
                            animate={
                                isTablet
                                    ? { x: 0, opacity: 0 }
                                    : { y: 100, opacity: 0 }
                            }
                            transition={{
                                ease: 'easeOut',
                                duration: 1.0,
                            }}
                        >
                            <div className="container d-flex align-items-center justify-content-center flex-column">
                                <h5
                                    style={{
                                        backgroundColor: '#0e1d56',
                                        borderRadius: '20px',
                                        padding: '10px',
                                        color: 'white',
                                        marginTop: '5rem',
                                        paddingLeft: '30px',
                                        paddingRight: '30px',
                                    }}
                                >
                                    Hakkımızda
                                </h5>

                                <div className="col-lg-8 ">
                                    <h1
                                        className="slogan"
                                    >
                                        Faturaların kolay ve Heryerden Kesilmesi
                                        için buradayız
                                    </h1>
                                </div>

                                <div
                                    style={
                                        isTablet
                                            ? {
                                                  maxWidth:
                                                      window.innerWidth - 60,
                                                  marginBottom: '4em',
                                              }
                                            : {
                                                  maxWidth:
                                                      window.innerWidth - 120,
                                                  marginBottom: '4em',
                                              }
                                    }
                                >
                                    <Swiper
                                        slidesPerView={isTablet ? 1 : 3}
                                        loop={true}
                                        spaceBetween={30}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <img
                                                style={{width:"100%" ,height:"100%"}}
                                                src={everywhere}
                                                class="img-fluid rounded-top"
                                                alt=""
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={lookTelphone}
                                                class="img-fluid rounded-top"
                                                alt=""
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={relax}
                                                class="img-fluid rounded-top"
                                                alt=""
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img
                                                src={saveTime}
                                                class="img-fluid rounded-top"
                                                alt=""
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </motion.div>
                        <div className="container">
                            <motion.div
                                whileInView={{
                                    x: 0,
                                    rotate: 0,
                                    opacity: 1,
                                    y: 0,
                                }}
                                initial={false}
                                animate={
                                    isTablet
                                        ? { x: 0, opacity: 0 }
                                        : { y: 100, opacity: 0 }
                                }
                                transition={{
                                    ease: 'easeOut',
                                    duration: 1.0,
                                }}
                            >
                                <p
                                    style={
                                        isTablet
                                            ? {
                                                  textAlign: 'center',
                                                  marginTop: '1.5rem',
                                               //   fontFamily: 'fantasy',
                                                  fontSize: '29px',
                                                  lineHeight: '35px',

                                                  fontWeight: 500,
                                                  color: '#101010',
                                                  marginBottom: '48px',
                                              }
                                            : {
                                                  textAlign: 'center',
                                                  marginTop: '3rem',
                                               //   fontFamily: 'fantasy',
                                                  fontSize: '48px',
                                                  lineHeight: '95px',
                                                  letterSpacing: ' 0.1em',
                                                  fontWeight: 500,
                                                  color: '#101010',
                                                  marginBottom: '32px',
                                              }
                                    }
                                >
                                    Birinci Yazılım Firmamız, mobil pazarında
                                    vergi mükellefleri için hayatlarını
                                    kolaylaştıran ürünler sunmaktadır
                                </p>
                            </motion.div>

                            <motion.div
                                whileInView={{
                                    x: 0,
                                    rotate: 0,
                                    opacity: 1,
                                    y: 0,
                                }}
                                initial={false}
                                animate={
                                    isTablet
                                        ? { x: 0, opacity: 0 }
                                        : { y: 100, opacity: 0 }
                                }
                                transition={{
                                    ease: 'easeOut',
                                    duration: 1.3,
                                }}
                            >
                                <div
                                    className={isTablet ? ' ' : 'row mt-5 mb-5'}
                                    style={ isTablet ? {marginTop:"5rem"}:{ marginTop: '15rem' }}
                                >
                                    <div
                                        className={
                                            isTablet ? 'mt-5' : 'col-8 col-md-5'
                                        }
                                    >
                                        <img
                                            src={Earsiv}
                                            className="img-fluid rounded"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className={
                                            isTablet ? ' mt-3' : 'col-10 col-md-7'
                                        }
                                    >
                                        <p
                                            style={{
                                                textAlign: 'justify',
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Uygulamamız, Gelir İdaresi
                                            Başkanlığı (GIB) E-arşiv E-fatura
                                            portalında zorunlu olan 5.000 TL ve
                                            30.000 TL fatura kesme işlemlerine
                                            (ve isteğe bağlı olarak 5.000 TL
                                            altına da fatura kesme işlemlerine)
                                            entegre edilmiş bir mobil
                                            uygulamadır. Bu uygulamada, satış,
                                            tevkifat, özel matrah, istisna ve
                                            iade faturaları oluşturulabilecek ve
                                            düzenlenen faturalar
                                            görüntülenebilecektir. Ayrıca,
                                            faturaların pdf formatında
                                            kaydedilmesi ve paylaşılması,
                                            faturaların imzalanması, müşteri
                                            oluşturulması ve kayıtlı not
                                            oluşturulması gibi işlemler de
                                            yapılabilmektedir. Buna ek olarak,
                                            adına düzenlenen faturalar da bu
                                            uygulama üzerinden
                                            görüntülenebilmektedir.
                                        </p>
                                        <h4>Uygulamamızın Özellikleri</h4>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Gıb sistemi ile uyumlu faturalanızı
                                            oluşturabilirsiniz.
                                        </li>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Satış, Tevkifat , Özel Matrah ,
                                            İstisna ve İade faturaları
                                            oluşturma.
                                        </li>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Düzenlediğimiz faturaları
                                            görüntüleme
                                        </li>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Faturaları pdf formatında kayıt etme
                                            ve paylaşma.
                                        </li>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Faturaları İmzalama.
                                        </li>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Kayıtlı müşteri oluşturma.
                                        </li>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Kayıtlı not oluşturma.
                                        </li>
                                        <li
                                            style={{
                                                fontSize: 'large',
                                                fontFamily: 'ubuntu',
                                            }}
                                        >
                                            Adınıza düzenlen faturalar
                                            görüntüleme.
                                        </li>
                                    </div>
                                </div>
                            </motion.div>

                            <motion.div
                                whileInView={{
                                    x: 0,
                                    rotate: 0,
                                    opacity: 1,
                                    y: 0,
                                }}
                                initial={false}
                                animate={
                                    isTablet
                                        ? { x: 0, opacity: 0 }
                                        : { y: 100, opacity: 0 }
                                }
                                transition={{
                                    ease: 'easeOut',
                                    duration: 1.0,
                                }}
                            >
                                <div
                                    className={isTablet ? 'mt-5' : 'row mt-5 mb-5'}
                                >
                                    <div
                                        className={
                                            isTablet ? 'mt-5' : 'col-8 col-md-5'
                                        }
                                    >
                                        <img
                                            src={Tevkifat}
                                            className="img-fluid rounded"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className={
                                            isTablet ? 'mt-3' : 'col-10 col-md-7'
                                        }
                                    >
                                        <p
                                            style={{
                                                textAlign: 'justify',
                                                fontFamily: 'ubuntu',
                                                fontSize: 'large',
                                            }}
                                        >
                                            Tevkifatlı Nakliye Faturası
                                            uygulaması, nakliye hizmetleri sunan
                                            işletmelerin vergi ödemeleri için
                                            gereken tevkifat işlemlerini
                                            kolaylaştırmak amacıyla tasarlanmış
                                            bir uygulamadır. Bu uygulama
                                            sayesinde işletmeler, nakliye
                                            hizmeti verdiği müşterilerinden
                                            aldığı ödemelerde yer alan tevkifat
                                            tutarlarını hızlı ve kolay bir
                                            şekilde hesaplayabilirler.
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </section>
                </ContentContainer>
            </InnerPageContainer>

            <Footer />
        </PageContainer>
    );
}
