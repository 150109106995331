import React from 'react';
import styled from 'styled-components';
import { BrandLogo } from '../brandLogo';
import Marginer  from '../marginer';

import { Link, useRouteMatch } from 'react-router-dom';
import { deviceSize } from '../responsive';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const NavbarContainer = styled.div`
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5em;

    background-color: ${({ useTransparent }) =>
        useTransparent ? 'transparent' : '#0e1d56'};
`;


const AccessibilityContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: start;
    margin-left: 20rem;
`;

const AnchorLink = styled(Link)`
    font-size: 16px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    transition: all 200ms ease-in-out;
    cursor: pointer;

    &:hover {
        filter: contrast(0.9);
        color: #17d1ff;
    }
    @media screen and (max-width: ${deviceSize.mobile}px) {
        color: #afafaf;
    }
`;

// const Seperator = styled.div`
//   min-height: 35%;
//   width: 1px;
//   background-color: #fff;
// `;
function ActiveLink({ to, activeOnlyWhenExact }) {
    let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact
    });
  
    return (
        match?.isExact
    );
}

export default function Navbar(props) {
    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { useTransparent } = props;

    const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });
    const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });

    return (
        <NavbarContainer useTransparent={useTransparent}>
            {/* <BrandLogo logoSize={isMobile ? 32 : 32} /> */}
            {(isMobile || isTablet )? (
                <>
                    <nav
                        className="navbar navbar-dark  fixed-top"
                        style={{ backgroundColor: '#1e3556' }}
                    >
                        <div className="container-fluid">
                            <BrandLogo
                                logoSize={isMobile ? 35 : 32}
                                className="navbar-brand"
                            />
                            <button
                                className="navbar-toggler"
                                onClick={handleShow}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <Offcanvas
                                className="text-bg-dark"
                                show={show}
                                onHide={handleClose}
                                placement={'end'}
                                responsive="lg"
                            >
                                <Offcanvas.Header>
                                    <h5
                                        className="offcanvas-title"
                                        id="offcanvasDarkNavbarLabel"
                                    >
                                        Menü
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        onClick={handleClose}
                                    ></button>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                        <li className="nav-item ">
                                            <AnchorLink
                                                to="/"
                                               style={ActiveLink({to:"/",activeOnlyWhenExact:"/home"}) ? {color: "white"} :  {} }
                                                className="nav-link "

                                            >
                                                Ana Sayfa
                                            </AnchorLink>
                                        
                                        </li>
                                        <li className="nav-item">

                                        <AnchorLink
                                                to="/hakkimizda"
                                                className="nav-link "

                                                style={ActiveLink({to:"/hakkimizda",activeOnlyWhenExact:"/hakkimizda"}) ? {color: "white"} :  {} }

                                            >
                                            Hakkımızda
                                            </AnchorLink>
                                 
                                        </li>


                                        <li className="nav-item">
                                            <AnchorLink
                                                className="nav-link"
                                                to="/blog"
                                                style={ActiveLink({to:"/blog",activeOnlyWhenExact:"/blog"}) ? {color: "white"} :  {} }

                                            >
                                                Blog
                                            </AnchorLink>
                                        </li>

                                        <li className="nav-item">
                                            <AnchorLink
                                                className="nav-link"
                                                to="/"
                                            >
                                                İletişim
                                            </AnchorLink>
                                        </li>
                                    </ul>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                    </nav>
                </>
            ) : (
                <>
                <BrandLogo logoSize={isMobile ? 32 : 32} />
                
               
                <AccessibilityContainer>
                    <AnchorLink to="/">Ana Sayfa</AnchorLink>
                    <Marginer direction="horizontal" margin={75} />

                    <AnchorLink to="/hakkimizda">Hakkımızda</AnchorLink>
                    <Marginer direction="horizontal" margin={75} />


                    <AnchorLink to="/blog">Blog</AnchorLink>
                    <Marginer direction="horizontal" margin={75} />

                    <AnchorLink to="/">İletişim</AnchorLink>
                    <Marginer direction="horizontal" margin={75} />
                </AccessibilityContainer>
                </>
            )}
        </NavbarContainer>
    );
}
