import React from 'react';
import styled from 'styled-components';
import { deviceSize } from '../../components/responsive';
import login from '../../images/PhoneImage/login.png';
import mainmenu from '../../images/PhoneImage/mainmenu.png';
import viewss from '../../images/PhoneImage/viewss.png';
import malvehizmet1 from '../../images/PhoneImage/malvehizmet1.png';
import malvehizmet2 from '../../images/PhoneImage/malvehizmet2.png';
import FaturaTipi from '../../images/PhoneImage/FaturaTipi.png';

import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';

const ServicesContainer = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Title = styled.h2`
    font-weight: 900;
    color: #000;

    @media screen and (max-width: ${deviceSize.mobile}px) {
        font-size: 25px;
    }
`;


const VerticalStack = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    border-width: 10px;
    border-color: white;
    margin-bottom: 1em;
    img {
        width: 100%;
        border-radius: 30px;
        //height: 70%;
    } 
`;


export default function Services(props) {
    const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

    useEffect(() => {
        console.log(window.innerWidth);
    }, []);

    return (
        <ServicesContainer>

            <Title
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '2rem',
                }}
            >
                Kullanımı Kolay Arayüzü
            </Title>

            {isMobile ? (
                <>
                    <motion.div
                        whileInView={{
                            x: 0,
                            rotate: 0,
                            opacity: 1,
                        }}
                        initial={false}
                        animate={{ x: 0, opacity: 0 }}
                        transition={{
                            ease: 'easeOut',
                            duration: 1,
                        }}
                        style={{
                            width: window.innerWidth - 30,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <VerticalStack>
                            <img src={login} alt="My SVG" />
                        </VerticalStack>
                    </motion.div>

                    <motion.div
                        whileInView={{
                            x: 0,
                            rotate: 0,
                            opacity: 1,
                        }}
                        initial={false}
                        animate={{ x: 0, opacity: 0 }}
                        transition={{
                            ease: 'easeOut',
                            duration: 1,
                        }}
                        style={{
                            width: window.innerWidth - 30,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <VerticalStack>
                            <img src={mainmenu} alt="My SVG" />
                        </VerticalStack>
                    </motion.div>

                    <motion.div
                        whileInView={{
                            x: 0,
                            rotate: 0,
                            opacity: 1,
                        }}
                        initial={false}
                        animate={{ x: 0, opacity: 0 }}
                        transition={{
                            ease: 'easeOut',
                            duration: 1,
                        }}
                        style={{
                            width: window.innerWidth - 30,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <VerticalStack>
                            <img src={viewss} alt="My SVG" />
                        </VerticalStack>
                    </motion.div>

                    <motion.div
                        whileInView={{
                            x: 0,
                            rotate: 0,
                            opacity: 1,
                        }}
                        initial={false}
                        animate={{ x: 0, opacity: 0 }}
                        transition={{
                            ease: 'easeOut',
                            duration: 1,
                        }}
                        style={{
                            width: window.innerWidth - 30,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <VerticalStack>
                            <img src={FaturaTipi} alt="My SVG" />
                        </VerticalStack>
                    </motion.div>

                    <motion.div
                        whileInView={{
                            x: 0,
                            rotate: 0,
                            opacity: 1,
                        }}
                        initial={false}
                        animate={{ x: 0, opacity: 0 }}
                        transition={{
                            ease: 'easeOut',
                            duration: 1,
                        }}
                        style={{
                            width: window.innerWidth - 30,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <VerticalStack>
                            <img src={malvehizmet1} alt="My SVG" />
                        </VerticalStack>
                    </motion.div>
                </>
            ) : (
                <>
                    <div className="container text-center">
                        <div className="row ">
                            <div className="col">
                                <motion.div
                                    style={{ width: '25rem' }}
                                    whileInView={{
                                        x: 0,
                                        rotate: 0,
                                        opacity: 1,
                                    }}
                                    initial={false}
                                    animate={{ x: -100 }}
                                    transition={{
                                        ease: 'easeOut',
                                        duration: 1.0,
                                    }}
                                >
                                    <div
                                        className="card "
                                        style={{
                                            width: '25rem',
                                            borderRadius: '30px',
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <img
                                            style={{
                                                padding: '34px',
                                                borderRadius: '65px',
                                            }}
                                            className="card-img-top"
                                            src={login}
                                            alt="My SVG"
                                        />
                                    </div>
                                </motion.div>
                            </div>

                            <div className="col">
                                <motion.div
                                    style={{
                                        width: '25rem',
                                        borderRadius: '30px',
                                    }}
                                    whileInView={{
                                        x: 0,
                                        y: 0,
                                        rotate: 0,
                                        opacity: 1,
                                    }}
                                    initial={false}
                                    animate={{ x: 0, y: 100 }}
                                    transition={{
                                        ease: 'easeOut',
                                        duration: 1.0,
                                    }}
                                >
                                    <div
                                        className="card "
                                        style={{
                                            width: '25rem',
                                            borderRadius: '30px',
                                        }}
                                    >
                                        <img
                                            style={{
                                                padding: '34px',
                                                borderRadius: '65px',
                                            }}
                                            className="card-img-top"
                                            src={mainmenu}
                                            alt="My SVG"
                                        />
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col">
                                <motion.div
                                    style={{ width: '25rem' }}
                                    whileInView={{
                                        x: 0,
                                        rotate: 0,
                                        opacity: 1,
                                    }}
                                    initial={false}
                                    animate={{ x: 100 }}
                                    transition={{
                                        ease: 'easeOut',
                                        duration: 1.0,
                                    }}
                                >
                                    <div
                                        className="card"
                                        style={{
                                            width: '25rem',
                                            borderRadius: '30px',
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <img
                                            style={{
                                                padding: '34px',
                                                borderRadius: '65px',
                                            }}
                                            className="card-img-top"
                                            src={viewss}
                                            alt="My SVG"
                                        />
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <motion.div
                                    style={{ width: '25rem' }}
                                    whileInView={{
                                        x: 0,
                                        rotate: 0,
                                        opacity: 1,
                                    }}
                                    initial={false}
                                    animate={{ x: -100 }}
                                    transition={{
                                        ease: 'easeOut',
                                        duration: 1.0,
                                    }}
                                >
                                    <div
                                        className="card "
                                        style={{
                                            width: '25rem',
                                            borderRadius: '30px',
                                        }}
                                    >
                                        <img
                                            className="card-img-top"
                                            style={{
                                                padding: '34px',
                                                borderRadius: '65px',
                                            }}
                                            src={FaturaTipi}
                                            alt="My SVG"
                                        />
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col">
                                <motion.div
                                    style={{ width: '25rem' }}
                                    whileInView={{
                                        x: 0,
                                        y: 0,
                                        rotate: 0,
                                        opacity: 1,
                                    }}
                                    initial={false}
                                    animate={{ x: 0, y: 100 }}
                                    transition={{
                                        ease: 'easeOut',
                                        duration: 1.0,
                                    }}
                                >
                                    <div
                                        className="card "
                                        style={{
                                            width: '25rem',
                                            borderRadius: '30px',
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <img
                                            className="card-img-top"
                                            style={{
                                                padding: '34px',
                                                borderRadius: '65px',
                                            }}
                                            src={malvehizmet1}
                                            alt="My SVG"
                                        />
                                    </div>
                                </motion.div>
                            </div>

                            <div className="col">
                                <motion.div
                                    style={{ width: '25rem' }}
                                    whileInView={{
                                        x: 0,
                                        rotate: 0,
                                        opacity: 1,
                                    }}
                                    initial={false}
                                    animate={{ x: 100 }}
                                    transition={{
                                        ease: 'easeOut',
                                        duration: 1.0,
                                    }}
                                >
                                    <div
                                        className="card "
                                        style={{
                                            width: '25rem',
                                            borderRadius: '30px',
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <img
                                            style={{
                                                padding: '34px',
                                                borderRadius: '65px',
                                            }}
                                            className="card-img-top"
                                            src={malvehizmet2}
                                            alt="My SVG"
                                        />
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </ServicesContainer>
    );
}
