import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import  Footer  from '../../components/footer';
import  Navbar  from '../../components/navbar';
import PageContainer, {
    InnerPageContainer,
} from '../../components/pageContainer';
import { deviceSize } from '../../components/responsive';
import tevkifat from '../../images/Blog/tevkifat.png';
import satis from '../../images/Blog/satis.png';
import Iade from '../../images/Blog/Iade.png';
import OzelMatrah from '../../images/Blog/OzelMatrah.png';
import Istisna from '../../images/Blog/Istisna.png';

const ContentContainer = styled.div`
    width: 100%;
    // max-width: ${deviceSize.laptop}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    background-color: aliceblue;

    @media screen and (max-width: ${deviceSize.mobile}px) {
        padding: 5px;
    }
`;
export const blogPosts = [
    {
        id: 1,
        title: 'Tevkifatlı Nakliye Faturası',
        content: `  Tevkifat ile nakliye faturası kesim
    işlemi artık 2000 tl bütün
    faturalarınızı için Elektronik
    ortamda düzenlenme zorunluluğu
    gelmiştir.Bu yazımızda bu işlemin
    nasıl yapıldığı anlatılmaktadır.`,
        src: tevkifat,
        object: (
            <>
                <div className="container-md ">
                    <div className="Titlediv ">
                        <h2
                            style={{ fontFamily: 'ubuntu' }}
                            className="title mt-3"
                        >
                            Tevkifatlı Fatura
                        </h2>
                    </div>
                    <div className="topParagraph ">
                        <div className="">
                            <p
                                className="paragraf "
                                style={{
                                    fontFamily: 'ubuntu',
                                    textAlign: 'justify',
                                }}
                            >
                                Tevkifat, para kesintilerini ifade eden bir
                                terimdir. Vergi bağlamında, vergilerin alıcı ve
                                satıcı arasında bölüştürülmesi anlamına gelir.
                                Devlet, alacağı katma değer vergisini (KDV)
                                satıcıdan ve alıcıdan alır ve bu vergi iki taraf
                                arasında bölüştürülmüş olur. Tevkifat
                                hesaplanırken farklı yöntemler kullanılabilir ve
                                her sektör için KDV tevkifat oranı değişebilir.
                                İki tür tevkifat vardır: Gelir Vergisi Tevkifatı
                                ve KDV Tevkifatı. Kısmi tevkifat, belirlenmiş
                                bir kısmının alıcı tarafından ödenmesi ve diğer
                                kısmının işlemi yapan tarafından ödenmesidir.
                                Tam tevkifat ise işlem bedeli üzerinden verginin
                                tamamının alıcılar tarafından ödenmesidir.
                                Tevkifatlı fatura, normal fatura ile aynıdır
                                ancak vergi alıcı ve satıcı arasında
                                bölüştürülerek ikisinden de alınır.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="header">
                            <h3
                                style={{
                                    fontFamily: 'ubuntu',
                                    marginBottom: '2rem',
                                }}
                            >
                                Tevkifatlı Nakliye Faturası
                            </h3>
                        </div>
                        <div
                            className="topParagraph mb-4"
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                            }}
                        >
                            <p>
                                Bilindiği üzere, 16 Şubat 2021 tarihinde ve
                                31397 sayılı Resmi Gazete'de yayınlanan 35 Sıra
                                No.lu tebliğ ile "kara yolu ile yapılan yük
                                taşıma hizmet" alımları KDV tevkifatı uygulaması
                                kapsamına alınmıştır.
                            </p>
                            <p>
                                Bu yasa ile birlikte 2000 tl ve üzeri faturalar
                                tevkifatlı olarak düzenlenmesi gerekmetedir.
                            </p>
                        </div>

                        <div
                            style={{
                                fontFamily: 'ubuntu',
                                marginBottom: '2rem',
                                textAlign: 'justify',
                            }}
                        >
                            <h4>Tevkifat Hesabı Nasıl yapılır</h4>
                            <p>
                                1 Mart 2022 tarihinden itibaren, nakliye
                                faturası tutarı 2000 TL veya daha fazla olan
                                işletmelerin e-arşiv fatura kullanması zorunlu
                                hale geldi. Ancak, fatura alıcısı bir vergi
                                mükellefi değilse, fatura tutarı 5000 TL'ye
                                kadar kağıt fatura olarak düzenlenebilir.
                            </p>

                            <p style={{ fontWeight: 600 }}>
                                Peki biz bu hesabı nasıl yaparız ?
                            </p>
                            <p>
                                Edirneden İstanbula Kil nakliyesi götürmüş
                                olalım.
                            </p>
                            <p>
                                Fiyatımız 3000 tl + (%18) kdv olduğunu
                                varsayalım.
                            </p>

                            <p>
                                Bildiğimiz üzere yük taşımacılığında uygulanann
                                kdv tevkifat oranı (2/10) dur
                            </p>
                            <div style={{ marginLeft: '2rem' }}>
                                <li
                                    style={{
                                        fontFamily: 'ubuntu',
                                        textAlign: 'justify',
                                        fontSize: '17px',
                                    }}
                                >
                                    Bu durumda toplam kdv tutarı : 3000 x %18 =
                                    540 TL
                                </li>
                                <li
                                    style={{
                                        fontFamily: 'ubuntu',
                                        textAlign: 'justify',
                                        fontSize: '17px',
                                    }}
                                >
                                    Tevkifat tutarı ise : 540 x %20 = 108 TL
                                </li>
                                <li
                                    style={{
                                        fontFamily: 'ubuntu',
                                        textAlign: 'justify',
                                        fontSize: '17px',
                                    }}
                                >
                                    Tevkifat uygulandıktan sonran Kdv tutarı 540
                                    - 108 = 432 TL
                                </li>
                                <li
                                    style={{
                                        fontFamily: 'ubuntu',
                                        textAlign: 'justify',
                                        fontSize: '17px',
                                    }}
                                >
                                    Firmadan almanız gerekn toplam ücret 3000 +
                                    432 = 3432 TL dir.
                                </li>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '2rem' }}>
                        <h4>
                            Nakliye Faturası Birinci Earşiv Fatura ile Nasıl
                            kesilir
                        </h4>
                        <p>
                            Aşagıdaki videoda Birinci E-arşiv Fatura ile
                            Faturalanızı nasıl kesiceğiniz gösterilmiştir
                        </p>
                        <div className="ratio ratio-16x9">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/RysH2pwPc3U"
                                title="YouTube video player"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 2,
        title: 'Satış Faturası',
        content: `Bir ürünü satıığımızda elektronik ortamda fatura kesmemizi gerekiyor burada bu makalede bu işlemlerin nasıl yapıldığı gösterilmektedir.`,
        src: satis,
        object: (
            <>
                <div className="container-md ">
                    <div className="Titlediv ">
                        <h2
                            style={{ fontFamily: 'ubuntu' }}
                            className="title mt-3"
                        >
                            Satış Faturası
                        </h2>
                    </div>
                    <div className="topParagraph ">
                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            Satış Faturası Nedir?
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            Satış faturası, bir işletmenin müşterilerine sattığı
                            mal veya hizmetler için düzenlediği resmi bir
                            belgedir. Satış faturası, işletme ile müşteri
                            arasındaki ticari işlemi belgeleyerek vergi ve
                            muhasebe kayıtlarında kullanılır. Ayrıca,
                            müşterilerin bu mal veya hizmetler için ödediği
                            tutarı gösterir.
                        </p>

                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            Türkiye'de Satış Faturası Düzenleme Zorunluluğu
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            Türkiye'de bir işletmenin herhangi bir mal veya
                            hizmet için fatura düzenlemesi zorunludur. Satış
                            faturası, müşteri tarafından ödenen tutarı ve vergi
                            oranlarını göstermelidir. Fatura ayrıca işletmenin
                            ticari unvanını, adresini, vergi kimlik numarasını,
                            fatura numarasını, tarihini ve mal veya hizmetin
                            adını ve miktarını da içermelidir.
                        </p>

                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            Satış Faturası Düzenleme Yöntemleri
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                Türkiye'de satış faturası düzenleme yöntemleri arasında manuel fatura düzenleme, elektronik fatura düzenleme ve ödeme kaydedici cihaz (OKE) kullanımı yer alır.
                        </p>
                        <ol>
                        <li>Manuel Fatura Düzenleme: Manuel fatura düzenleme, basılı fatura formlarını kullanarak el ile yapılan bir işlemdir.</li>
                        <li>Elektronik Fatura Düzenleme: Elektronik fatura düzenleme, işletmelerin elektronik ortamda fatura düzenlemesine olanak tanır. Elektronik fatura düzenleme için özel bir yazılım kullanılır.</li>
                            <li>Ödeme Kaydedici Cihaz (OKE): Ödeme kaydedici cihaz, satış işlemlerini kaydetmek için kullanılan bir cihazdır. OKE, satışları kaydeder ve fatura düzenleme işlemini otomatik olarak gerçekleştirir.</li>
                            </ol>
                    </div>

                    <div style={{ marginTop: '2rem' }}>
                        <h4>
                            Satış Faturası Birinci E-Arşiv Fatura ile Nasıl
                            Kesilir
                        </h4>
                        <p>
                            Aşagıdaki videoda Birinci E-arşiv Fatura ile Satış
                            faturanızı nasıl kesiceğiniz gösterilmiştir.
                        </p>
                        <div className="ratio ratio-16x9">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/zN0pbv479rM"
                                title="YouTube video player"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 3,
        title: 'Iade Faturası',
        content:
            ' Satın Aldığımız ürünleri Iade edebiliriz.Peki bu işlem nasıl gerçekleşir.Bu makale bu konu hakkında yazılmıştır.',
        src: Iade,
        object: (
            <>
                <div className="container-md ">
                    <div className="Titlediv ">
                        <h2
                            style={{ fontFamily: 'ubuntu' }}
                            className="title mt-3"
                        >
                            Iade Fatura
                        </h2>
                    </div>
                    <div className="topParagraph ">
                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            Iade Faturası Nedir
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İade faturası, bir malın satışından sonra alıcı
                            tarafından iade edilmesi durumunda düzenlenen bir
                            belgedir. İade faturası, satıcı ve alıcı arasındaki
                            ticari işlemin detaylarını içerir ve önceden
                            düzenlenen bir faturanın iptal edilmesi anlamına
                            gelir.
                        </p>
                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            İade faturası nasıl düzenlenir?
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İade faturası, Türkiye'de fatura düzenleme
                            mevzuatına uygun olarak hazırlanmalıdır. İade
                            faturasında, önceden düzenlenen faturanın numarası,
                            tarihi ve tutarı belirtilmelidir. Ayrıca, iade
                            edilen malın cinsi, miktarı ve değeri de
                            belirtilmelidir. İade faturası düzenlenirken, iade
                            edilen malın nedeni de açıklanmalıdır.
                        </p>
                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            İade faturası muhasebe kaydı nasıl yapılır?
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İade faturası, muhasebe kaydı yapılırken, önceden
                            düzenlenen faturanın iptal edildiği ve iade edilen
                            malın stoktan düşüldüğü kaydı yapılmalıdır. İade
                            faturası, satıcı firma tarafından müşteri hesabından
                            tahsil edilen tutarın geri ödenmesini sağlar.
                        </p>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            Sonuç olarak, Türkiye'deki iade faturaları, ticari
                            işlemler sırasında oluşabilecek hataların
                            düzeltilmesi için önemli bir araçtır. İade faturası
                            düzenlerken, Türkiye'de fatura düzenleme mevzuatına
                            uygun hareket etmek ve gerekli bilgileri doğru bir
                            şekilde belirtmek önemlidir.
                        </p>
                    </div>

                    <div style={{ marginTop: '2rem' }}>
                        <h4>
                            Iade Faturası Birinci E-Arşiv Fatura ile Nasıl
                            Kesilir
                        </h4>
                        <p>
                            Aşagıdaki videoda Birinci E-arşiv Fatura ile Iade
                            faturanızı nasıl kesiceğiniz gösterilmiştir.
                        </p>
                        <div className="ratio ratio-16x9">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/RysH2pwPc3U"
                                title="YouTube video player"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 4,
        title: 'Ozel Matrah',
        content:
            'Vergi mevzuatına göre düzenlenen bir fatura türünü ifade eder. Bu fatura türü, belli bir mükellefin diğer bir mükellefe veya kişiye mal veya hizmet sağladığında, özel matrah olarak adlandırılan bir hesaplamaya göre vergilendirilmesi gereken durumlarda kullanılır.',
        src: OzelMatrah,
        object: (
            <>
                <div className="container-md ">
                    <div className="Titlediv ">
                        <h2
                            style={{ fontFamily: 'ubuntu' }}
                            className="title mt-3"
                        >
                            Özel Matrah Fatura
                        </h2>
                    </div>
                    <div className="topParagraph ">
                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            Özel Matrah Fatura Nedir
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            Özel Matrah Fatura, Türkiye'de faaliyet gösteren
                            işletmelerin, vergi kanunlarına göre belirtilen
                            hallerde, fatura düzenleme esaslarını
                            değiştirebilmeleri için kullanılan bir fatura
                            türüdür. Bu fatura türü, işletmelerin vergi
                            mükellefiyetleri ve işletme faaliyetleri ile ilgili
                            çeşitli avantajlar sağlamaktadır.
                        </p>

                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            Özel Matrah Fatura, satışa konu olan mal veya hizmet
                            bedeli yerine, satıcının belirlediği bir matraha
                            göre düzenlenir. Bu matrah, satıcının belirlediği
                            bir rakam olabileceği gibi, alıcının teklif ettiği
                            bir fiyat da olabilir. Bu fatura türünde, satıcının
                            belirlediği matrah üzerinden hesaplanan KDV tutarı,
                            standart KDV oranına göre hesaplanan tutardan daha
                            düşük olabilir. Bu sayede, işletmeler vergi
                            yükümlülüklerini azaltabilirler.
                        </p>

                        <h3>Özel Matrah Şekilleri</h3>

                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            Milli Piyango, Spor Toto vb. Oyunlar
                        </li>
                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            At yarışları ve diğer müşterek bahis ve talih
                            oyunları
                        </li>
                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            Profesyonel Sanatçıların Yer Aldığı Gösteriler,
                            Konserler, Profesyonel Sporcuların Katıldığı Sportif
                            Faaliyetler, Maçlar, Yarışlar ve Yarışmalar
                        </li>
                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            Gümrük Depolarında ve Müzayede Mahallerinde Yapılan
                            Satışlar
                        </li>
                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            Tütün Mamülleri
                        </li>
                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            Gümüşten Mamul veya Gümüş İçeren Ziynet Eşyaları ile
                            Sikke Gümüşlerin Teslimi
                        </li>
                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            Belediyeler taraf. yap. şehiriçi yolcu
                            taşımacılığında kullanılan biletlerin ve kartların
                            bayiler tarafından satışı
                        </li>
                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            Ön Ödemeli Elektronik Haberleşme Hizmetleri
                        </li>

                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            TŞOF Tarafından Araç Plakaları ile Sürücü
                            Kurslarında Kullanılan Bir Kısım Evrakın Teslimi
                        </li>

                        <li
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '17px',
                            }}
                        >
                            KDV Uygulanmadan Alınan İkinci El Motorlu Kara
                            Taşıtı veya Taşınmaz Teslimi
                        </li>

                        <p style={{ marginTop: '1rem', fontWeight: '600' }}>
                            Yukarıda bahsedilen konularda Özel Matrah uygulana
                            bilir.
                        </p>
                    </div>

                    <div style={{ marginTop: '2rem' }}>
                        <h4>
                            Özel Matrah Fatura Birinci E-Arşiv Fatura ile Nasıl
                            Kesilir
                        </h4>
                        <p>
                            Aşagıdaki videoda Birinci E-arşiv Fatura ile Özel
                            Matrah faturanızı nasıl kesiceğiniz gösterilmiştir.
                        </p>
                        <div className="ratio ratio-16x9">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/RysH2pwPc3U"
                                title="YouTube video player"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 5,
        title: 'Istisna Faturası',
        content:
            'Vergi mevzuatına göre düzenlenen bir fatura türüdür. Bu fatura türü, belli bir mükellefin diğer bir mükellefe veya kişiye sağladığı mal veya hizmetlerin vergiden istisna tutulduğu durumlarda kullanılır.',
        src: Istisna,
        object: (
            <>
                <div className="container-md ">
                    <div className="Titlediv ">
                        <h2
                            style={{ fontFamily: 'ubuntu' }}
                            className="title mt-3"
                        >
                            İstisna Fatura
                        </h2>
                    </div>
                    <div className="topParagraph ">
                        <h3
                            style={{
                                fontFamily: 'ubuntu',
                            }}
                        >
                            İstisna Fatura Nedir
                        </h3>
                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İstisna Fatura, Türkiye'de KDV Kanunu'na göre
                            belirli şartlar altında KDV'ye tabi olmayan işlemler
                            için düzenlenen bir fatura türüdür. İstisna Fatura,
                            KDV mükellefleri tarafından düzenlenebilir ve fatura
                            üzerinde KDV yer almaz. Bu nedenle, işlem KDV
                            istisnasına tabi ise, satıcı veya hizmet sağlayıcı
                            tarafından fatura düzenlenirken KDV hesaplanmaz ve
                            fatura tutarı KDV dahil olarak belirtilmez.
                        </p>

                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İstisna Fatura düzenleme konusunda dikkatli olunması
                            gereken bazı hususlar vardır. Özellikle, işlem KDV
                            istisnasına tabi ise, bu durum fatura üzerinde
                            açıkça belirtilmelidir. Aksi takdirde, KDV idaresi
                            tarafından yapılan incelemelerde işletmeler için
                            cezai yaptırımlar söz konusu olabilir.
                        </p>

                        <h3>
                            Peki, İstisna Fatura hangi işlemler için
                            düzenlenebilir?
                        </h3>

                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İstisna Fatura, belirli mal ve hizmetlerin satışı
                            veya kiralanması, ihracat işlemleri, serbest
                            bölgelerdeki işlemler, diplomatik misyonlarla ilgili
                            işlemler, özel tüketim vergisine tabi malların
                            ithalatı ve taşınmazların satışı gibi çeşitli
                            işlemleri kapsar.
                        </p>

                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İstisna Fatura düzenleme işlemi KDV mükellefleri
                            için önemli bir avantaj sağlayabilir. Çünkü KDV
                            hesaplamadan fatura düzenleyerek, müşteriye daha
                            uygun fiyatlar sunabilirler. Ayrıca, işletmelerin
                            maliyetlerini düşürmesine ve rekabet güçlerini
                            artırmasına yardımcı olabilir.
                        </p>

                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            Ancak, işletmelerin bu konuda dikkatli olmaları
                            gerekmektedir. İşlem KDV istisnasına tabi ise, bu
                            durum fatura üzerinde açıkça belirtilmelidir.
                            Ayrıca, KDV istisnası uygulanması için gereken
                            şartların tam olarak sağlanmış olması ve belgelerin
                            doğru şekilde düzenlenmiş olması gerekmektedir. Aksi
                            takdirde, KDV idaresi tarafından yapılan
                            incelemelerde işletmeler için cezai yaptırımlar söz
                            konusu olabilir.
                        </p>

                        <p
                            style={{
                                fontFamily: 'ubuntu',
                                textAlign: 'justify',
                                fontSize: '18px',
                            }}
                        >
                            İstisna Fatura konusunda daha ayrıntılı bilgi almak
                            isteyenler, KDV Kanunu'nun ilgili maddelerini
                            inceleyebilirler. Ayrıca, bir muhasebeci veya vergi
                            danışmanı ile görüşerek daha kapsamlı bir bilgi
                            edinebilirler.
                        </p>
                    </div>

                    <div style={{ marginTop: '2rem' }}>
                        <h4>
                            İstisna Fatura Birinci E-Arşiv Fatura ile Nasıl
                            Kesilir
                        </h4>
                        <p>
                            Aşagıdaki videoda Birinci E-arşiv Fatura ile İstisna
                            faturanızı nasıl kesiceğiniz gösterilmiştir.
                        </p>
                        <div className="ratio ratio-16x9">
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/RysH2pwPc3U"
                                title="YouTube video player"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
];
export default function BlogPage() {
    const [search, setSearch] = useState('');
    let filtreliObjeler = blogPosts.filter(function(obje) {
        return obje.title.includes(search);
      });
      
      
      
      
    return (
        <PageContainer>
            <Navbar />
            <InnerPageContainer>
                <ContentContainer>
                    <section className="blog" style={{width:"100%"}}>
                        <div className="row mb-4">
                            <div className="col-md-5 mx-auto">
                                {/* <div className="small fw-light mx-3 mb-1 ">Ara </div> */}
                                <div className="input-group">
                                    <input
                                        className="form-control border-end-0 border rounded-pill"
                                        type="search"
                                        value={search}
                                        onChange={(event) => {
                                            event.preventDefault();
                                            setSearch(event.target.value);
                                            debugger
                                        }}
                                        id="search-input-blog"
                                    />
                                    <span className="input-group-append">
                                        <button
                                            className="btn rounded-pill "
                                            style={{ marginLeft: '-40px' }}
                                            type="button"
                                            onClick={(event) => {
                                                event.preventDefault();

                                                const inputElement =
                                                    document.querySelector(
                                                        '#search-input-blog'
                                                    );
                                                inputElement.focus();
                                            }}
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                            
                            {blogPosts.filter(function(obje) {
        return obje.title.includes(search);
      }).map((post) => (
                                <div key={post.id} className="col">
                                    <div className="card h-100">
                                        <img
                                            src={post.src}
                                            className="card-img-top"
                                            alt="..."
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                {post.title}
                                            </h5>
                                            <p className="card-text">
                                                {post.content}
                                            </p>
                                        </div>
                                        <Link
                                            style={{
                                                width: 'fit-content',
                                                marginLeft: '10px',
                                                marginBottom: '10px',
                                            }}
                                            to={`/blog/${post.id}`}
                                            className="btn btn-primary"
                                        >
                                            Devamını Oku
                                        </Link>
                                        <div className="card-footer">
                                            <small className="text-muted">
                                                Paylaşım Tarihi : 20.02.2023
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </ContentContainer>
            </InnerPageContainer>

            <Footer />
        </PageContainer>
    );
}
