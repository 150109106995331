import React from "react";
import styled from "styled-components";
import  Footer  from "../../components/footer";
import  Navbar  from "../../components/navbar";
import PageContainer , {
  InnerPageContainer,
} from "../../components/pageContainer";
import { deviceSize } from "../../components/responsive";
import Services from "../HomePage/services";

const ContentContainer = styled.div`
  width: 100%;
 // max-width: ${deviceSize.laptop}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  background-color: aliceblue;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 5px;
  }
`;

export function ServicePage(props) {
  return (
    <PageContainer>
      <Navbar  />
      <InnerPageContainer>
        <ContentContainer>
        <Services />
        </ContentContainer>

      </InnerPageContainer>

      <Footer />
    </PageContainer>
  );
}
