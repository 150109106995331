import React from 'react';
import styled from 'styled-components';
import "./index.css";

export function AppUsersAgreementPage(props) {
    const ContentContainer = styled.div`
        height: '100%';
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    `;

    return (
        <ContentContainer>
            <div
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <h1 className='headerUp'>MOBİL UYGULAMA KULLANICI SÖZLEŞMESİ</h1>
            </div>
            <p style={{ marginLeft: 'auto', marginRight: '20px' ,fontSize:"small" }}>
                Son Güncellenme Tarihi 12/12/2022{' '}
            </p>

            <div style={{ alignContent: 'start' ,marginLeft:"12px" ,marginRight:"12px"}}>
                <h2 className='header'>MADDE 1– TARAFLAR</h2>
                <p className='paragraf'>
                    İşbu sözleşme ve ekleri (EK-1 Gizlilik Sözleşmesi)
                    “İnönü/Eskişehir” adresinde mukim “Birinci E-Arşiv Fatura”
                    (bundan böyle "Birinci Fatura" olarak anılacaktır) ile
                    Birinci E-Arşiv Fatura mobil uygulamalarından iş bu
                    sözleşmede belirtilen koşullar dahilinde yararlanan
                    Kullanıcı ile karşılıklı olarak kabul edilerek yürürlüğe
                    girmiştir.
                </p>
                <h2 className='header'>MADDE 2– TANIMLAR</h2>
                <p className='paragraf'>Sözleşmede Kullanılacak Olan Tanımlar:</p>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            <strong>2.1. Uygulama</strong> Birinci Fatura isimli
                            mobil uygulama tüm mobil cihazlarda çalışan mobil
                            uygulama yazılımı ve bu yazılım ile bağlantılı
                            olarak oluşturulan ürün ve hizmetlerin sunulduğu tüm
                            sanal gerçeklik mecralarını ifade etmektedir.
                            (Bundan böyle "Uygulama" olarak anılacaktır.)
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            <strong>2.2. Kullanıcı</strong> Uygulamadan Birinci
                            Fatura tarafından sunulan hizmetlerden işbu
                            sözleşmede belirtilen koşullar dahilinde yararlanan
                            kişileri ifade etmektedir. (Bundan böyle "Kullanıcı”
                            olarak anılacaktır.)
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            <strong>2.3. Sisteme Erişim Araçları:</strong>{' '}
                            Kullanıcının hesabını yönetim sayfası, Uygulamaya
                            erişim sağlayan kullanıcı adı, şifre, kod, parola
                            gibi yalnızca Kullanıcının bilgisinde olan bilgileri
                            ifade eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            <strong>2.4. İletişim Kanalları:</strong> Anında
                            bildirim, e-posta, sms, mms, telefon ile bildirim
                            gibi iletişim mecralarını ifade eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            <strong>2.5. Mesaj:</strong> Birinci Fatura’nın,
                            Kullanıcılara pazarlama, araştırma, bilgilendirme
                            vb. amaçlarla göndereceği, Kullanıcıların Birinci
                            Fatura tarafından kendilerine gönderilmesine izin
                            verdikleri mesajları ifade eder.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 3– KONU VE KAPSAM</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            <strong>3.1.</strong> İşbu sözleşmenin konusu;
                            Kullanıcıya yönelik olarak uygulamada mevcut olan ve
                            ileride Birinci Fatura tarafından sunulacak tüm ürün
                            ve hizmetlerin, bu ürün ve hizmetlerden yararlanma
                            şartlarının ve tarafların hak ve yükümlülüklerinin
                            tespitidir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            <strong>3.2.</strong> Kullanıcı, işbu sözleşme
                            hükümlerini kabul etmekle, Uygulama ve Portal içinde
                            yer alan kullanıma, üyeliğe ve hizmetlere ilişkin
                            Birinci Fatura tarafından açıklanan her türlü beyanı
                            da kabul etmiş olmaktadır. Kullanıcı, bahsi geçen
                            beyanlarda belirtilen her türlü hususa uygun olarak
                            davranacağını kabul, beyan ve taahhüt eder.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 4– UYGULAMA KULLANIM ŞARTLARI</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            <strong>4.1.</strong> Reşit olmayan veya tüzel kişi
                            üyeler için tüzel kişiyi temsil ve ilzam etmeye
                            yetkili olmayan kişiler kanalıyla yapılan
                            başvurular, kayıt işlemlerini tamamlamış olsa dahi
                            "Kullanıcının" doğurduğu haklardan yararlanamazlar.
                            Aksi yöndeki talep ve işlemler Birinci Fatura’nın
                            sorumluğunda değildir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            <strong>4.2.</strong> Birinci Fatura, her zaman
                            herhangi bir gerekçe göstermeden, herhangi bir
                            bildirimde bulunmadan ve herhangi bir tazminat ödeme
                            yükümlülüğü bulunmadan ve derhal yürürlüğe girecek
                            şekilde işbu sözleşmeyi tek taraflı olarak
                            feshedebilir ve Kullanıcının işbu Uygulamadan
                            yararlanmasına son verebilir.
                        </p>
                    </li>
                </ul>

                <h2 className='header'>MADDE 5– KARŞILIKLI HAK VE YÜKÜMLÜLÜKLER</h2>
                <h3 className='headerlow'>5.1. Kullanıcı Hak ve Yükümlülükleri:</h3>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            a) Kullanıcı, kullanıcı prosedürlerini yerine
                            getirirken, Uygulamadaki ürün veya hizmetlerden
                            faydalanırken ve Uygulamadaki ürün veya hizmetler
                            ile ilgili herhangi bir işlemi yerine getirirken
                            işbu sözleşme içerisinde yer alan tüm şartlara,
                            Birinci Fatura internet sitesinde belirtilen
                            kurallara ve yürürlükteki tüm mevzuata uygun hareket
                            edeceğini, işbu sözleşmede belirtilen tüm şart ve
                            kuralları okuduğunu, anladığını ve onayladığını
                            kabul eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            b) Kullanıcı, Birinci Fatura’nın yasal mevzuat
                            gereği resmi makamlara açıklama yapmakla yükümlü
                            olduğu durumlarda, resmi makamlarca usulü dairesinde
                            bu bilgilerin talep edilmesi halinde, Kullanıcılara
                            ait, Uygulama dahilinde edinilen bilgileri resmi
                            makamlara açıklamaya yetkili olacağını ve bu sebeple
                            kendisinden her ne nam altında olursa olsun tazminat
                            talep edilemeyeceğini kabul eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            c) Kullanıcı, Birinci Fatura’nın iş bu Sözleşme
                            konusu ürün ve hizmetleri ifasına yönelik olarak
                            ve/veya bu amaçla anlaşma akdettiği iş ortaklarına,
                            Kullanıcıya ait ticari bilgileri açıklamaya yetkili
                            olacağını, bu hususu kabul ettiğini beyan ederek bu
                            sebeple Birinci Fatura’dan her ne nam altında olursa
                            olsun tazminat talep edilemeyeceğini kabul eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            d) Kullanıcı, Uygulamaya erişmek için kullandığı
                            Sisteme Erişim Araçlarını (kullanıcı adı, şifre, kod
                            gibi) gizli tutmakla yükümlüdür. Sisteme Erişim
                            Araçlarını kullanma hakkı münhasıran Kullanıcıya
                            aittir. Kullanıcı işbu bilgilerini üçüncü şahıslara
                            ifşa etmeyecektir. Sisteme Erişim Araçlarının üçüncü
                            bir şahıs tarafından kullanımının sonuçlarından
                            tamamı ile Kullanıcı sorumludur. Birinci Fatura, bu
                            gibi usulsüz kullanımları yapan kişilerin
                            kimliklerini tespit etmekle yükümlü değildir.
                            Kullanıcı, yukarıda belirtilen hükümler saklı kalmak
                            kaydıyla, işbu bilgilerinin üçüncü bir şahıs
                            tarafından kullanımından ve bunun neticelerinden
                            kendisinin sorumlu olduğunu, işbu bilgilerin
                            kullanımı suretiyle yapılan işlemlerin kendisini
                            bağlayıcı olduğunu kabul, beyan ve taahhüt eder.
                            Kullanıcı, şifresinin başkalarınca ele geçirildiğini
                            öğrendiği takdirde Birinci Fatura’yu derhal
                            bilgilendirmekle yükümlüdür.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            e) Kullanıcı, Uygulama dahilinde kendisi tarafından
                            sağlanan bilgilerin ve içeriklerin doğru ve hukuka
                            uygun olduğunu kabul ve taahhüt eder. Birinci
                            Fatura, Kullanıcı tarafından Birinci Fatura’ya
                            iletilen veya Uygulama üzerinden kendisi tarafından
                            yüklenen, değiştirilen ve sağlanan bilgilerin ve
                            içeriklerin doğruluğunu araştırma, bu bilgi ve
                            içeriklerin güvenli, doğru ve hukuka uygun olduğunu
                            taahhüt ve garanti etmekle yükümlü ve sorumlu
                            olmadığı gibi, söz konusu bilgi ve içeriklerin
                            yanlış veya hatalı olmasından dolayı ortaya çıkacak
                            hiçbir zarardan da sorumlu değildir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            f) Kullanıcı, kendine ait Portal’dan yapılan tüm
                            işlemlerin münhasıran ve müstakilen kendisi
                            tarafından gerçekleştirildiğini kabul, beyan ve
                            taahhüt eder. Kullanıcı, Birinci Fatura’ya verdiği
                            iletişim kanallarının yalnızca kendisine ait ve
                            yalnızca kendi kontrolü altındaki iletişim kanalları
                            olduğunu ve bu iletişim kanallarını aktif olarak
                            kullandığını taahhüt eder. Aksi halde, Kullanıcı
                            iletişim kanallarının üyenin kendisine ait
                            olmamasından veya İletişim Kanallarını aktif olarak
                            kullanmamasından dolayı Birinci Fatura’nın
                            uğrayabileceği her türlü maddi ve manevi zararı
                            tazminle yükümlü olacaktır.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            g) Birinci Fatura'nın sunduğu ürün ve hizmetlerden
                            yararlananlar, Uygulama dahilinde yalnızca hukuka
                            uygun amaçlarla işlem yapabilirler. Kullanıcının,
                            Uygulama dahilinde yaptığı her işlem ve eylemdeki
                            hukuki ve cezai sorumluluk kendisine aittir.
                            Kullanıcılar, Uygulamayı kullanırken Türk Ceza
                            Kanunu, Türk Ticaret Kanunu, Borçlar Kanunu, 5651
                            sayılı İnternet Ortamında Yapılan Yayınların
                            Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla
                            Mücadele Edilmesi Hakkında Kanun, Fikir ve Sanat
                            Eserleri Kanunu, Marka, Patent, Faydalı Model,
                            Endüstriyel Tasarım Haklarının Korunması ile ilgili
                            kanun hükmünde kararnameler ve yasal düzenlemeler,
                            halihazırda yürürlükte olan ilgili her türlü mevzuat
                            hükümleri ile Birinci Fatura'nın Uygulamaya ilişkin
                            olarak yayımlayacağı bildirimlere uymayı kabul eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            h) Kullanıcı işbu uygulamanın Birinci Fatura’ya ait
                            olduğunu ve onun tarafından işletildiğini kabul
                            eder. İşbu uygulamanın içeriği Fikri ve Sınai haklar
                            doğrultusunda korunmaktadır. Kullanıcı, Birinci
                            Fatura ve/veya başka bir üçüncü şahsın aynı veya
                            şahsi haklarına, malvarlığına tecavüz teşkil edecek
                            nitelikteki Uygulama dahilinde bulunan resimleri,
                            metinleri, görsel ve işitsel imgeleri, video
                            klipleri, dosyaları, veri tabanlarını, katalogları
                            ve listeleri çoğaltmayacağını, kopyalamayacağını,
                            dağıtmayacağını, işlemeyeceğini kabul ve taahhüt
                            etmektedir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            ı) Uygulamada ve Uygulama üzerinden link verilen
                            internet sitelerinde, Kullanıcı de dahil olmak üzere
                            üçüncü kişiler tarafından yayınlanan içeriklerden
                            dolayı Birinci Fatura’nın sorumluluğu
                            bulunmamaktadır. Herhangi bir üçüncü kişi tarafından
                            sağlanan ve yayınlanan bilgilerin, içeriklerin,
                            görsel ve işitsel imgelerin doğruluğu ve hukuka
                            uygunluğunun taahhüdü bütünüyle bu eylemleri
                            gerçekleştiren kişilerin sorumluluğundadır. Birinci
                            Fatura, üçüncü kişiler tarafından sağlanan
                            hizmetlerin ve içeriklerin güvenliğini, doğruluğunu
                            ve hukuka uygunluğunu taahhüt ve garanti
                            etmemektedir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            j) Kullanıcı ve Birinci Fatura birbirinden bağımsız
                            taraflardır. İşbu Sözleşme'nin Taraflarca
                            onaylanması ve uygulanması, aralarında ortaklık,
                            temsilcilik veya işçi– işveren ilişkisi olduğu
                            sonucunu doğurmaz.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            k) Kullanıcı işbu Sözleşme kapsamında uygulamayı
                            kullanmasından doğan her hangi bir hatadan dolayı
                            Birinci Fatura’dan her ne nam altında olursa olsun
                            tazminat talep edilemeyeceğini kabul eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            l) Kullanıcı, Birinci Fatura'nın kendisi ile
                            elektronik haberleşme araçları ile iletişim
                            kurmasını, kampanya ve promosyon gibi
                            bilgilendirmeleri yapmasını kabul, beyan ve taahhüt
                            eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            m) Kullanıcı işbu Sözleşme kapsamında üçüncü
                            kişilere göndereceği referans mesajlarının hukuka ve
                            ahlaka aykırı unsurlar içermeyeceğini kabul, beyan
                            ve taahhüt eder. Kullanıcı, göndereceği elektronik
                            postalar ile üçüncü kişilerce istenmeyen elektronik
                            posta iletişimi olarak nitelendirilebilecek
                            yoğunlukta bir iletişimde bulunmayacağını kabul,
                            beyan ve taahhüt eder. Kullanıcı, bu madde hükmüne
                            aykırı olarak yapacağı bütün iletişimin
                            sorumluluğunun kendisine ait olduğunu, her ne
                            sebeple olursa olsun bu iletişimlerden kaynaklı
                            olarak Birinci Fatura’ya bir dava ve talep
                            yöneltilmesi halinde; bu dava ve talebin Birinci
                            Fatura tarafından kendisine ihbar edilebileceğini,
                            Birinci Fatura’nın savunma yapması için gerekli olan
                            her türlü bilgi ve içeriği kendisine savunma için
                            gerekli yasal sürelerin geçirilmemesini teminen
                            derhal sağlayacağını, bahsi geçen dava ve talep
                            sonucunda Birinci Fatura’nın herhangi bir zarara
                            uğraması durumunda bahsi geçen zararın Birinci
                            Fatura tarafından, tazminat hakları saklı kalmak
                            kaydıyla, kendisine rücu ettirilebileceğini kabul,
                            beyan ve taahhüt eder. Kullanıcının iş bu maddeye
                            aykırı hareket etmesi sebebiyle Birinci Fatura iş bu
                            Sözleşmeyi herhangi bir bildirimde bulunmadan tek
                            taraflı olarak feshetme, üyeliği askıya alma veya
                            iptal etme hakkını saklı tutmaktadır.{' '}
                        </p>
                    </li>{' '}
                    <li>
                        <p className='paragraf'>
                            n) Kullanıcı, ilgili formları doldurduktan sonra
                            "Kullanıcı Sözleşmesi 'ni okudum, müzakere ettim ve
                            sözleşme şartlarını kabul ediyorum" maddesini
                            onayladıktan sonra iş bu Sözleşme kapsamında
                            Kullanıcı olma ehliyetine sahip olduğunu beyan ve
                            taahhüt etmektedir. Aksi halde, bu hususun Birinci
                            Fatura tarafından tespit edilmesi durumunda
                            Kullanıcının sözleşmesi derhal feshedilir.
                        </p>
                    </li>
                </ul>
                <h3 className='headerlow'>5.2. Kullanıcı Hak ve Yükümlülükleri:</h3>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            a) Birinci Fatura, Uygulama'da sunulan ürün ve
                            hizmetleri ve içerikleri her zaman tek taraflı
                            olarak değiştirebilme, Kullanıcının sisteme
                            yüklediği bilgileri ve içerikleri Kullanıcı da dahil
                            olmak üzere üçüncü kişilerin erişimine kapatabilme
                            ve silme hakkını saklı tutmaktadır. Birinci Fatura,
                            bu hakkını hiçbir bildirimde bulunmadan ve önel
                            vermeden kullanabilir. Birinci Fatura tarafından
                            Kullanıcıdan talep edilen değişiklik ve/veya
                            düzeltme istekleri belirtilen süre içerisinde yerine
                            getirilmediği takdirde doğmuş ve doğabilecek
                            zararlardan Birinci Fatura sorumlu olmayacak ve
                            Kullanıcının Uygulamayı kullanma hakkını
                            dondurabilme veya sonlandırma hakkına sahip
                            olacaktır.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            b) Referans kolaylığı veya çeşitli sebepler
                            nedeniyle Uygulama içerisinde Birinci Fatura
                            kontrolünde olmayan bir takım web sitelerine veya
                            içeriklere linkler verilebilir. Bu linkler bahsi
                            geçen web sitesini veya içerik sahibini destekleyici
                            mahiyette olmadığı gibi, web sitesi veya içerikte
                            yer alan bilgilere yönelik herhangi bir türde bir
                            beyan veya garanti niteliği de taşımamaktadır.
                            Birinci Fatura, üçüncü kişilerin web sitelerinin
                            muhtevasının burada sayılanlarla sınırlı olmamakla
                            birlikte; yasal, doğru, güvenilir, yerinde ve uygun
                            olduğu, içeriğinde kullanılan bilgi, veri, yazılım,
                            ürün veya hizmetlerin ticarete elverişli nitelikte
                            olduğu, web sitesinin işletme ve idaresinin hatasız
                            ve kesintisiz olacağı, aksaklıklar, kusur ve
                            bozuklulukların düzeltileceği, web sitesinin zarar
                            verici unsurlardan ve virüslerden temizlenmiş olduğu
                            hususlarında herhangi bir öneri ve tavsiyede
                            bulunmamakta ve bu hususlarda kullanıcıya herhangi
                            bir güven, beyan ve teminat vermemektedir. Uygulama
                            üzerindeki linkler vasıtasıyla erişilen portallar,
                            web siteleri, dosyalar ve içerikler, bu linkler
                            vasıtasıyla erişilen portallar veya web sitelerinden
                            sunulan hizmetler veya ürünler veya bunların içeriği
                            hakkında Birinci Fatura’nun herhangi bir sorumluluğu
                            yoktur.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            c) Birinci Fatura, Uygulama'da yer alan Kullanıcı
                            bilgilerini Hizmetler dışında da Kullanıcı
                            güvenliği, kendi yükümlülüğünü ifa ve bazı
                            istatistiki değerlendirmeler ve iç denetimler için
                            veya demografik bilgi olarak reklam ve/veya tanıtım
                            için dilediği biçimde kullanabilir, bunları bir veri
                            tabanı üzerinde tasnif edip muhafaza edebilir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            d) Birinci Fatura, işbu Uygulama ve uzantısında
                            mevcut her tür hizmet, ürün, Uygulamayı kullanma
                            koşulları ile Uygulamada sunulan bilgileri önceden
                            bir ihtara gerek olmaksızın değiştirme, yeniden
                            organize etme, Uygulamayı durdurma hakkını saklı
                            tutar. Değişiklikler, Uygulamada yayım anında
                            yürürlüğe girer. Kullanıcılar, Uygulamanın kullanımı
                            ya da Uygulamaya giriş ile bu değişiklikleri de
                            kabul etmiş sayılır. Değişiklikler, Birinci Fatura
                            tarafından belirlenen mecra üzerinden Kullanıcılara
                            duyurulur.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            e) Birinci Fatura, Uygulama kapsamında verdiği
                            hizmetlerin sürekli aktif ve erişilebilir olacağına
                            dair hiçbir garanti vermemektedir. Birinci Fatura
                            özellikle yargı ve sair yetkili resmi merci karar ve
                            uygulamaları, mücbir sebepler, üçüncü kişilerin
                            sebep olduğu durumlar, Internet bağlantı hizmeti
                            sağlayan kuruluşlardan kaynaklanan aksaklıklar ve
                            gecikmeler ve benzeri dış etkenler, yanlış kullanım,
                            Birinci Fatura’dan kaynaklanabilecek teknik arıza ve
                            sair aksaklıklar, tamir çalışmaları veya diğer
                            yönlendirmeler sonucu meydana gelen aksaklıklar
                            konusunda hiçbir şekilde sorumluluk kabul etmez.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                            f) Birinci Fatura gerektiğinde, ağın işletim
                            güvenliğinin tehlikede olması, ağa erişimin
                            devamlılığı, ağda, yazılımda veya kayıtlı dosyalarda
                            meydana gelebilecek arızaların önüne geçebilmek,
                            muhtemel aksaklıkları engellemek veya etkisini
                            azaltmak adına ve gerekli gördüğü diğer durumlarda
                            hizmete erişimi sınırlandırabilir veya durdurabilir.
                        </p>
                    </li>
                </ul>

                <h2 className='header'>MADDE 6– HİZMETLER</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            <strong>6.1.</strong> Birinci Fatura, Uygulama aracılığıyla
                            Kullanıcılara fatura oluşturma , fatura görüntüleme
                            , fatura inceleme , fatura imzalama ve adiniza
                            kesilen faturaları görüntüleme hizmetlerini
                            sunacaktır. Birinci Fatura, sunduğu hizmetlerin
                            içeriğinde istediği zaman değişiklik yapma hakkını
                            saklı tutar.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 7– GİZLİLİK</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            Birinci Fatura, Kullanıcılarla ilgili bilgileri işbu
                            Sözleşme'nin kapsamı dışında işbu Sözleşme'nin EK-1
                            bölümünde yer alan ve Kullanıcı Sözleşmesi'nin
                            ayrılmaz bir parçası olan "Gizlilik Sözleşmesi"
                            kapsamında kullanabilir. Birinci Fatura,
                            Kullanıcılara ait gizli bilgileri, işbu Sözleşme'nin
                            kapsamı dışında ancak "Gizlilik Sözleşmesi"nde
                            belirtilen koşullar dahilinde üçüncü kişilere
                            açıklayabilir veya kullanabilir.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 8– UYGULANACAK HUKUK VE YETKİLİ MAHKEME</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            İşbu Sözleşme'nin uygulanmasında ve yorumlanmasında
                            Türk Hukuku uygulanacaktır. İşbu Sözleşme'den dolayı
                            doğan veya doğabilecek her türlü ihtilafın hallinde
                            İstanbul Merkez Mahkemeleri ve İcra Daireleri
                            yetkilidir.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 9– FİKRİ MÜLKİYET HAKLARI</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            Uygulama'nın (genel görünüm, tasarım, metin, imge,
                            logo, ikon, demonstratif, yazılı, elektronik, grafik
                            veya makinede okunabilir şekilde sunulan teknik
                            verileri, Birinci Fatura markası, uygulanan iş
                            metodu ve iş modeli, yazılım kodu ve diğer kodlar da
                            dahil ve fakat bunlarla sınırlı olmamak kaydıyla)
                            tüm unsurları Birinci Fatura’ya aittir ve/veya
                            Birinci Fatura tarafından üçüncü bir kişiden alınan
                            telif hakkı altında kullanılmaktadır. Fikri ve Sınai
                            haklar kapsamında korunan Birinci Fatura’ya ait tüm
                            bu unsurlar önceden izin alınmadan ve kaynak
                            gösterilmeden değiştirilemez, kopyalanamaz,
                            çoğaltılamaz, başka bir lisana çevrilemez, yeniden
                            yayımlanamaz, yeniden satılamaz, paylaşılamaz,
                            dağıtılamaz, sergilenemez, Kullanıcı Sözleşmesi
                            kapsamı dışında kullanılamaz veya bunlardan türemiş
                            çalışmalar yapılamaz veya hazırlanamaz, aksi şekilde
                            davranılması halinde, sorumlu kişi/kişiler Birinci
                            Fatura’nun uğradığı zararı ve/veya lisans verenler
                            de dahil üçüncü kişilerin uğradıkları zararlardan
                            dolayı Birinci Fatura’dan talep edilen tazminat
                            miktarını, mahkeme masrafları ve avukatlık ücreti de
                            dahil olmak üzere karşılamakla yükümlü olacaklardır.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 10– SÖZLEŞME DEĞİŞİKLİKLERİ</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            Birinci Fatura, tamamen kendi takdirine bağlı ve tek
                            taraflı olarak, işbu Kullanıcı Sözleşmesi'ni veya
                            herhangi bir hükmünü ve eklerini, uygun göreceği
                            herhangi bir zamanda, Uygulama'da ilan etmek
                            suretiyle değiştirebilir. İşbu Kullanıcı
                            Sözleşmesi'nin değişen hükümleri, ilan edildikleri
                            tarihte veya önceden belirtilen geçerlilik tarihi
                            olması halinde ise geçerlilik tarihinde geçerlilik
                            kazanacak; geri kalan hükümler, aynen yürürlükte
                            kalarak hüküm ve sonuçlarını doğurmaya devam
                            edecektir. İşbu Kullanıcı Sözleşmesi, üyelerin tek
                            taraflı beyanları ile değiştirilemez.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 11– MÜCBİR SEBEPLER</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            <strong>11.1.</strong> Mücbir sebep terimi, doğal afet, isyan, savaş,
                            grev, Birinci Fatura’nın gerekli bilgi güvenliği
                            önlemleri almasına karşın Uygulama, Portal ve
                            sisteme yapılan saldırılar da dahil ve fakat
                            bunlarla sınırlı olmamak kaydıyla Birinci Fatura’nın
                            makul kontrolü haricinde gelişen ve Birinci
                            Fatura’nun gerekli özeni göstermesine rağmen
                            önleyemediği kaçınılamayacak olaylar olarak
                            yorumlanacaktır.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>11.2.</strong> Mücbir sebep sayılan tüm durumlarda, Taraflar
                            işbu Sözleşme ile belirlenen edinimlerinden herhangi
                            birini geç veya eksik ifa etme veya ifa etmeme
                            nedeniyle yükümlü değildir.
                        </p>
                    </li>
                </ul>

                <h2 className='header'>MADDE 12– KAYITLARIN GEÇERLİLİĞİ</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            Kullanıcı, işbu Sözleşme' den doğabilecek
                            ihtilaflarda Birinci Fatura’nın kendi veri
                            tabanında, sunucularında tuttuğu elektronik ve
                            sistem kayıtlarının, ticari kayıtlarının, defter
                            kayıtlarının, mikrofilm, mikrofiş ve bilgisayar
                            kayıtlarının muteber bağlayıcı, kesin ve münhasır
                            delil teşkil edeceğini ve bu maddenin HMK 193. madde
                            anlamında delil sözleşmesi niteliğinde olduğunu
                            kabul, beyan ve taahhüt eder.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>MADDE 13– SÖZLEŞMENİN FESHİ</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                            İşbu Kullanıcı Sözleşmesi, Kullanıcı Uygulamay üye
                            olduğu sürece yürürlükte kalacak ve taraflar arası
                            hüküm ve sonuçlarını doğurmaya devam edecektir.
                        </p>
                    </li>
                </ul>

                <h2 className='header'>MADDE 14– İLETİŞİM ADRESLERİ</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                        <strong>14.1.</strong> Kullanıcıların, Birinci Fatura’ya bildirdiği
                            elektronik posta adresi, bu sözleşme ile ilgili
                            olarak yapılacak her türlü bildirim için yasal
                            adresin isteneceği elektronik posta olarak kabul
                            edilir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>14.2.</strong> Taraflar, mevcut elektronik postalarındaki
                            değişiklikleri yazılı olarak diğer tarafa 3 (üç) gün
                            içinde bildirmedikçe, eski elektronik postalara
                            yapılacak isteklerin geçerli olacağını ve
                            kendilerine yapılmış sayılacağını kabul ederler.
                        </p>
                    </li>
                </ul>

                <h2 className='header'>MADDE 15–YÜRÜRLÜK</h2>
                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                        <strong>15.1.</strong> Kullanıcı, işbu sözleşmede yer alan maddelerin
                            tümünü okuduğunu, anladığını, kabul ettiğini ve
                            kendisiyle ilgili olarak verdiği bilgilerin
                            doğruluğunu onayladığını beyan, kabul ve taahhüt
                            eder.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>15.2.</strong> İşbu Sözleşme, Kullanıcının, üyelik talebine,
                            elektronik olarak onay verilmesi ile karşılıklı
                            olarak kabul edilerek yürürlüğe girmiş olup, ekleri
                            ile birlikte uygulanacaktır. Ekler ile işbu Sözleşme
                            hükümleri arasında çelişki olması halinde iş bu
                            Sözleşme hükümleri geçerli olacaktır.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>15.3.</strong> İşbu Sözleşme 15 (onbeş) madde ve 1 (bir) adet
                            Ek sözleşmeden oluşmakta olup, Kullanıcının sitede
                            yer alan Kullanıcı Sözleşmesi'ni onaylaması
                            neticesi, tarafların serbest iradeleri ile
                            kurulmuştur. Ek sözleşmeler işbu Sözleşme'nin
                            ayrılmaz bir parçasıdır.
                        </p>
                    </li>
                </ul>
                <h2 className='header'>EK - GİZLİLİK SÖZLEŞMESİ</h2>

                <ul
                    style={{
                        listStyleType: 'none',
                    }}
                >
                    <li>
                        <p className='paragraf'>
                        <strong>1.</strong> Birinci Fatura, kişisel bilgi ve veri güvenliğini önemsemekte ve bu hususta gerekli olan tüm tedbirleri almaya özen göstermektedir. Kullanıcılar da Uygulamayı kullanarak işbu gizlilik sözleşmesi hükümlerine uygun davranacaklarını kabul, beyan ve taahhüt ederler.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>2.</strong> İşbu Gizlilik Sözleşmesi, Uygulamanın tüm bölümlerinde geçerli olacaktır.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>3.</strong> Kullanıcıların bilgilerinin korunması ve gizliliğin sürdürülebilmesi Birinci Fatura’nın birinci önceliğidir. Bu nedenle Kullanıcıların vermiş olduğu bilgiler sözleşmede belirtilen kurallar ve amaçlar dışında herhangi bir kapsamda kullanılmayacak, üçüncü şahıslarla paylaşılmayacaktır. Birinci Fatura’nın, Kullanıcı tarafından siteye kayıt formunda belirtilen veya daha sonra kendisi tarafından güncellenen adresi, e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim bilgileri üzerinden mektup, e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim, pazarlama, bildirim ve diğer amaçlarla Kullanıcıya ulaşma hakkı bulunmaktadır. Kullanıcı, işbu sözleşmeyi kabul etmekle aksine bir yazılı bildirimi olmadığı müddetçe Birinci Fatura’nun kendisine yönelik yukarıda belirtilen iletişim faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>4.</strong> Birinci Fatura, Uygulama kapsamında Kullanıcı tarafından paylaşılmış olan bilgilere erişebilir. Birinci Fatura, bu bilgileri Uygulamanın kullanımına yönelik olarak yalnızca iştirakçileri ile paylaşacağını taahhüt eder. Kullanıcı da bu bilgilerin Uygulamanın amacına yönelik olarak kendisi ile iletişime geçilmek için kullanılacağını kabul eder. Birinci Fatura, kendisine iletilen kişisel verileri ve bilgileri, bilgilerin toplanması ile ilgili açıklanan yukarıdaki amaçlar dışında üçüncü kişilerle kesinlikle paylaşmayacak, satışını yapmayacak ve hiç bir şart altında kullanılmasına izin vermeyecektir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>5.</strong> Uygulamadaki sistemle ilgili sorunların tanımlanabilmesi ve Uygulamada çıkabilecek muhtemel sorunların acil olarak giderilmesi için, Birinci Fatura gerektiğinde Kullanıcıların IP adresini kaydedebilir ve bu kayıtları anılan bu amaçlarla kullanabilir. Bu IP adresleri, Birinci Fatura tarafından kullanıcılarını ve ziyaretçilerini genel anlamda tanımlamak ve kapsamlı şekilde demografik veri toplayabilmek amacıyla kullanılabilir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>6.</strong> Uygulama kapsamında elde edilen bilgiler Birinci Fatura ve işbirliği içinde olduğu diğer kişi ve kurumlar tarafından Kullanıcının kimliği hiç bir şekilde açığa çıkarılmaksızın sadece çeşitli istatistiki değerlendirmeler, izinli pazarlama, veri tabanı oluşturma çabaları ve pazar araştırmaları yapma gibi durumlar dahilinde kullanılabilir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>7.</strong> Birinci Fatura, Uygulama içerisinde başkaca sitelere link (bağlantı) sağlayabilir, anlaşmalı olduğu 3. Partilerin reklamlarını yayınlayabilir, Kullanıcıları reklamlar aracılığıyla reklam veren veya anlaşmalı 3. partilerin sitesine yönlendirebilir. Birinci Fatura, bu bağlantı yoluyla erişilen diğer Uygulamaların gizlilik uygulama ve politikalarına ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk taşımamaktadır.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>8.</strong> Birinci Fatura, aşağıda sayılan hallerde ise işbu gizlilik bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar;
                        </p>
                        <ul>
                            <li style={{fontSize:"small"}}>
                            Hukuk kurallarının getirdiği zorunluluklara uyulmasının gerektiği haller,
                            </li>
                            <li style={{fontSize:"small"}}>
                            Birinci Fatura’nın, Kullanıcılarıyla arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmalarıyla ilgili hallerde,
                            </li>
                            <li style={{fontSize:"small"}}>
                            Yetkili idari ve/veya adli makamlar tarafından usuli yöntemine uygun olarak yürütülen bir araştırma veya soruşturma doğrultusunda Kullanıcılarla ilgili bilgi talep edilmesi halleri,
                            </li>
                            <li style={{fontSize:"small"}}>
                            Kullanıcıların haklarını veya güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu haller.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>9.</strong> Birinci Fatura, kendisine verilen gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni tam olarak göstermeyi taahhüt etmektedir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>10.</strong> Birinci Fatura tarafından Uygulamalar içerisinde düzenlenebilecek periyodik veya periyodik olmayan anketlere cevap veren Kullanıcılardan talep edilebilecek bilgiler de, Birinci Fatura ve işbirliği içindeki kişi ya da kurumlar tarafından bu kullanıcılara doğrudan pazarlama yapmak, istatistiki analizler yapmak ve özel bir veri tabanı oluşturmak amacıyla da kullanılabilecektir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>11.</strong> Birinci Fatura, işbu gizlilik bildiriminde geçen hükümleri gerekli gördüğü zamanda Uygulamada yayınlamak şartıyla değiştirebilir. Birinci Fatura’nın değişiklik yaptığı gizlilik bildirimi hükümleri Uygulamada yayınlandığı tarihte yürürlüğe girmiş kabul edilir.
                        </p>
                    </li>
                    <li>
                        <p className='paragraf'>
                        <strong>12.</strong> İşbu Gizlilik Sözleşmesi, 12 (oniki) maddeden ibaret olup Mobil Uygulama Kullanıcı Sözleşmesinin ayrılmaz bir parçasıdır.
                        </p>
                    </li>
                </ul>
                <ul>
                    <li>Mail: info@birincifatura.com</li>
                </ul>
            </div>
        </ContentContainer>
    );
}
