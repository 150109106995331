import './App.css';
import { HomePage } from './containers/HomePage';
import { AppUsersAgreementPage } from './containers/AppUsersAgreementPage';
import {AppKvkPage } from './containers/AppKvkPage';

import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import { ServicePage } from './containers/ServicePage';
import UserFeedBack  from './containers/HomePage/userFeedBack';
import  UserManuel  from './containers/HomePage/userManuel';
import Hakkimda  from './containers/HakkımızdaPage/Hakkimda.jsx';
// import { CustomerAccessPage } from "./containers/customerAccessPage";
import React, { useState } from 'react';
import BlogPage from './containers/BlogPage/BlogPage';
import BlogPageDetail from './containers/BlogPage/BlogPageDetail';

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Loading from './containers/Loading';
import NotFound from './containers/NotFound';
import {UserDataDeletion} from './containers/UserDataDeletion/UserDataDeletion.jsx';


function App() {
    const [loading, setLoading] = useState(true);

       const location = useLocation();
      //  useLocation()
    
        useEffect(() => {
            setLoading(false);
        }, [location]);    
        
        return (
        <div className="App">
            <Router>
                {loading ? (
                    <Loading />
                ) : (
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/service" component={ServicePage} />
                        <Route path="/hakkimizda" component={Hakkimda} />
                        <Route path="/blog" exact component={BlogPage} />
                        <Route path="/blog/:id" component={BlogPageDetail}/>
                        <Route path="/user-comment" component={UserFeedBack} />
                        <Route path="/User/Data/deletion" component ={UserDataDeletion}/>
                        <Route
                            path="/mobil-uygulama-kullanici-sozlesmesi"
                            component={AppUsersAgreementPage}
                        />
                        <Route path="/kvkk-metni" component={AppKvkPage} />


                        <Route path="/user-manuel" component={UserManuel} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                )}
            </Router>
        </div>
    );
}
export default App;