import React from 'react';
import styled from 'styled-components';
import { deviceSize } from '../../components/responsive';
import { Navigation, Pagination, Scrollbar, A11y, EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cards';


import { useMediaQuery } from 'react-responsive';
import './userfeed.css';
import { motion } from 'framer-motion';



const Title = styled.h2`
    font-weight: 900;
    color: #000;
    text-align: center;
    @media screen and (max-width: ${deviceSize.mobile}px) {
        font-size: 25px;
    }
`;

export default function UserFeedBack(props) {
    const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });


    return (
        <>
            <section className='userFeed'>
            <div className="content" style={{    marginBottom:"50px"}}>
             <motion.div
                    whileInView={{
                        x: 0,
                        rotate: 0,
                        opacity: 1,
                        y:0
                    }}
                    initial={false}
                    animate={isMobile ? { x: 0, opacity: 0 } : { y: 50,opacity:0 }}
                    transition={{
                        ease: 'easeOut',
                        duration: 1.0,
                    }}
                >
                <div style={{marginLeft:"auto",marginRight:"auto" , alignItems:"center"}}>
                    <Title>Uygulamayı Kullananan müşterilerin yorumları</Title>

                </div>
                 <Swiper
                    style={isMobile ? {
                      width: '210px',
                      height: '310px',
                  } : {
                    width: "350px",
                    height:"480px"}}
                    //modules={[EffectCards]}
                    modules={[
                        Navigation,
                        Pagination,
                        Scrollbar,
                        A11y,
                        EffectCards,
                    ]}
                    spaceBetween={50}
                    slidesPerView={1}
                    // navigation
                    effect={'cards'}
                    grabCursor={true}
                    //     pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                    <SwiperSlide className="swiper-slide">
                        <div className="card ">
                            <div className="image-content">
                                <span className="overlay"></span>

                                <div className="card-image">
                                <img alt="Profil resmi" className="card-img" src="https://lh3.googleusercontent.com/a/AEdFTp4GyAR2GF3AS2JVWBZ3OOGVE5HRyBrx3sMkSmaC=mo"/>

                                </div>
                            </div>

                            <div className="card-content">
                                <h2 className="name">Ahmet Caymaz</h2>
                                <p className="description">
                                Güvenilir çok güzel bir uygulama çok beğendim.
                                </p>
                                <button className="button" 
                                         onClick={() =>
                                            (window.location.href =
                                                'https://play.google.com/store/apps/details?id=com.Birinci.EArsiv.Fatura')
                                        }
                                 >Daha Fazla</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                    <div className="card ">
                            <div className="image-content">
                                <span className="overlay"></span>

                                <div className="card-image">
                                <img alt="Profil resmi" className='card-img' src="https://lh3.googleusercontent.com/a-/AD5-WCnQzQIL0wsnUMr9BoomI9h_-3t3pTAC6R5tBZB93A"/>
                                </div>
                            </div>

                            <div className="card-content">
                                <h2 className="name">Ekrem Erkek</h2>
                                <p className="description">
                                Çok başarılı ve kullanımı kolay bir uygulama yapandan Allah razı olsun
                                </p>
                                <button className="button"         onClick={() =>
                            (window.location.href =
                                'https://play.google.com/store/apps/details?id=com.Birinci.EArsiv.Fatura')
                        } >Daha Fazla</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                    <div className="card ">
                            <div className="image-content">
                                <span className="overlay"></span>

                                <div className="card-image">
                                <img alt="Profil resmi" className='card-img' src="https://lh3.googleusercontent.com/a-/AD5-WCkjVIcAqGZNVvTkPEszXsb_oExf7pxcyQsVP2vrlA"/>

                                </div>
                            </div>

                            <div className="card-content">
                                <h2 className="name">Ramazan Akkulak</h2>
                                <p className="description">
                                İşlerimi kolayca hallettim, teşekkürler.
                                </p>
                                <button className="button"          onClick={() =>
                            (window.location.href =
                                'https://play.google.com/store/apps/details?id=com.Birinci.EArsiv.Fatura')
                        } >Daha Fazla</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                    <div className="card ">
                            <div className="image-content">
                                <span className="overlay"></span>

                                <div className="card-image">
                                <img alt="Profil resmi" className='card-img' src="https://lh3.googleusercontent.com/a-/AD5-WCntxQQ6wmAFzJNI_VPG-SRd89I99R5qsXxTfpBPJQ"/>

                                </div>
                            </div>

                            <div className="card-content">
                                <h2 className="name">Engin Birinci</h2>
                                <p className="description">
                                Uygulamanin kullanimi çok kolay. Teşekkürler Birinci Fatura
                                </p>
                                <button className="button"          onClick={() =>
                            (window.location.href =
                                'https://play.google.com/store/apps/details?id=com.Birinci.EArsiv.Fatura')
                        } >Daha Fazla</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                    <div className="card">
                            <div className="image-content">
                                <span className="overlay"></span>

                                <div className="card-image">
                                <img alt="Profil resmi" className='card-img' src="https://lh3.googleusercontent.com/a-/AD5-WCl5fTIJAqeuk7co-IQ4f8QVkrJGuOGYMB9e6Y7lDw"/> 
                                </div>
                            </div>

                            <div className="card-content">
                                <h2 className="name">Wellpast</h2>
                                <p className="description">
                                Sade ve anlaşılır, pdf özelliği çok kullanışlı
                                </p>
                                <button className="button"          onClick={() =>
                            (window.location.href =
                                'https://play.google.com/store/apps/details?id=com.Birinci.EArsiv.Fatura')
                        } >Daha Fazla</button>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper> 
                </motion.div>
            </div> 
            </section>
 
        </>
    );
}
