import React , { Suspense, lazy } from "react";
import styled from "styled-components";
// import { Footer } from "../../components/footer";
// import { Marginer } from "../../components/marginer";
// import { Navbar } from "../../components/navbar";
import {
  InnerPageContainer,
} from "../../components/pageContainer";
import { deviceSize } from "../../components/responsive";
import Loading from "../Loading";
// import { SpecialistAd } from "../../components/specialistAd";
// import { Services } from "./services";
// import { UserFeedBack } from "./userFeedBack";
// import { UserManuel } from "./userManuel";
//import { motion } from "framer-motion";
//import { PageContainer } from "../../components/pageContainer";
const ContentContainer = styled.div`
  width: 100%;
 // max-width: ${deviceSize.laptop}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 // padding: 1em;

  @media screen and (max-width: ${deviceSize.mobile}px) {
  //  padding: 5px;
  }
`;


const TopSection = lazy(() => import("./topSection"));
const PageContainer = lazy(() => import("../../components/pageContainer"));
// const InnerPageContainer = lazy(() => import("../../components/pageContainer"));
const Footer = lazy(() => import("../../components/footer/index"));
const Marginer = lazy(() => import("../../components/marginer/index"));
const Navbar = lazy(() => import("../../components/navbar"));
const SpecialistAd = lazy(() => import("../../components/specialistAd"));
const Services = lazy(() => import("./services"));
const UserFeedBack = lazy(() => import("./userFeedBack"));
const UserManuel = lazy(() => import("./userManuel"));



export function HomePage(props) {
  return (
    <Suspense fallback={ <Loading />}>
    <PageContainer>
   
      <TopSection>
        <Navbar useTransparent />
      </TopSection>

      <InnerPageContainer>
        <Marginer style={{  backgroundColor: "aliceblue"}}  direction="vertical" margin="2em" />
        <ContentContainer>
          <Services />
        </ContentContainer>
        <Marginer direction="vertical" margin="5em" />
        <SpecialistAd />
        <Marginer direction="vertical" margin="5em" />

        <UserFeedBack/>

        <Marginer direction="vertical" margin="5em" />

        <UserManuel/>
        <Marginer direction="vertical" margin="5em" /> 


      </InnerPageContainer>

      <Footer />
    </PageContainer>
    </Suspense>

  );
}
