import React from 'react';
import styled from 'styled-components';
import './index.css';

export function AppKvkPage(props) {
    const ContentContainer = styled.div`
        height: '100%';
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `;

    return (
        <ContentContainer>
            <section className='kvkk'>
            <div    style={{
                    marginLeft: '1rem',
                    marginRight: '0.5rem',
                }}>
                <h1 >KVKK Aydınlatma Metni</h1>
                <p style={{textAlign:"justify"}}>Sayın Müşterimiz,</p>
                <p style={{textAlign:"justify"}}>
                    Verilerinizin korunması bizim için önemlidir. Bu kapsamda,
                    KVKK (Kişisel Verilerin Korunması Kanunu) gereği sizleri
                    bilgilendirmek istiyoruz.
                </p>
                <h2>Veri Sorumlusu Kimdir?</h2>
                <p style={{textAlign:"justify"}}>Veri sorumlusu, Birinci Fatura olarak tanımlanmaktadır.</p>
                <h2>Kişisel Verileriniz Hangi Amaçlarla İşlenecektir?</h2>
                <p style={{textAlign:"justify"}}>
                    Kişisel verileriniz, uygulamamızın işlevlerini yerine
                    getirmek, müşteri hizmetlerimizden yararlanmanızı sağlamak,
                    sizinle iletişim kurmak, ürünlerimizi ve hizmetlerimizi size
                    sunmak, pazarlama ve reklam faaliyetleri yürütmek ve yasal
                    yükümlülüklerimizi yerine getirmek için işlenecektir.
                </p>
                <h2>Kişisel Verileriniz Kimlerle Paylaşılacak?</h2>
                <p style={{textAlign:"justify"}}>
                    Kişisel verileriniz, yasal yükümlülüklerimizin yerine
                    getirilmesi, hizmetlerimizin sağlanması, iş ortaklarımızla
                    paylaşım gerektiren faaliyetlerde bulunulması ve diğer
                    amaçlarla, belirtilen amaçlarla sınırlı olmak kaydıyla,
                    yalnızca ilgili kişilerle paylaşılmaktadır.
                </p>
                <h2>
                    Kişisel Verilerinizin İşlenmesine İlişkin Haklarınız
                    Nelerdir?
                </h2>
                <ul>
                    <li style={{textAlign:"justify"}} >Kişisel verilerinizin işlenip işlenmediğini öğrenme</li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin işlenme amacını ve bunların
                        amacına uygun kullanılıp kullanılmadığını öğrenme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin yurt içinde veya yurt dışında
                        aktarıldığı üçüncü kişileri öğrenme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                        halinde düzeltilmesini isteme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin işlenmesini gerektiren sebeplerin
                        ortadan kalkması halinde silinmesini veya yok edilmesini
                        isteme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin düzeltilmesi, silinmesi veya yok
                        edilmesi işlemlerinin, ilgili üçüncü kişilere
                        bildirilmesini isteme
                    </li>
                    <li style={{textAlign:"justify"}} >Kişisel verilerinizin işlenesine itiraz etme</li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin otomatik sistemler vasıtasıyla
                        analiz edilmesine itiraz etme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin kanuna aykırı olarak işlenmesi
                        sebebiyle zarar uğramanız halinde zararın giderilmesini
                        talep etme
                    </li>
                </ul>
                <h2>KVKK Rıza Metni</h2>
                <p style={{textAlign:"justify"}}>
                    Bu KVKK Rıza Metni, Birinci Fatura'nin KVKK kapsamında kişisel
                    verilerinizi toplama, kullanma ve işleme yöntemlerini ve
                    koşullarını açıklamaktadır.
                </p>
                <p style={{textAlign:"justify"}}>
                    Birinci Fatura tarafından işlenen kişisel verileriniz şunları
                    içerebilir:
                </p>
                <ul>
                    <li style={{textAlign:"justify"}} >Adınız ve soyadınız</li>
                    <li style={{textAlign:"justify"}} >
                        İletişim bilgileriniz (e-posta adresi, telefon numarası
                        vb.)
                    </li>
                
                    <li style={{textAlign:"justify"}} >
                        Uygulama içinde kullanılan kaydettiğiniz bilgiler (Notlar,MalveHizmet,Müşteriler vb...)
                    </li>
                    <li style={{textAlign:"justify"}} >İlgilendiğiniz ürünler ve hizmetler</li>
                </ul>
                <p style={{textAlign:"justify"}}>
                    Bu veriler, ürün ve hizmetlerimizi size sunmak, müşteri
                    hizmetlerimizden yararlanmanızı sağlamak, sizinle iletişim
                    kurmak, pazarlama ve reklam faaliyetleri yürütmek, yasal
                    yükümlülüklerimizi yerine getirmek, ve işlem yapmamızı
                    sağlamak gibi amaçlarla kullanılabilir.
                </p>
                <p style={{textAlign:"justify"}}>
                    Birinci Fatura tarafından toplanan kişisel verilerinizin
                    işlenmesine ilişkin KVKK kapsamında haklarınızı
                    kullanabilirsiniz. Bu haklar aşağıda sıralanmıştır:
                </p>
                <ul>
                    <li style={{textAlign:"justify"}} >Kişisel verilerinizin işlenip işlenmediğini öğrenme</li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin işlenme amacını ve bunların
                        amacına uygun kullanılıp kullanılmadığını öğrenme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin yurt içinde veya yurt dışında
                        aktarıldığı üçüncü kişileri öğrenme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                        halinde düzeltilmesini isteme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin işlenmesini gerektiren sebeplerin
                        ortadan kalkması halinde silinmesini veya yok edilmesini
                        isteme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin düzeltilmesi, silinmesi veya yok
                        edilmesi işlemlerinin, ilgili üçüncü kişilere
                        bildirilmesini isteme
                    </li>
                    <li style={{textAlign:"justify"}} >Kişisel verilerinizin işlenmesine itiraz etme</li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin otomatik sistemler vasıtasıyla
                        analiz edilmesine itiraz etme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin kanuna aykırı olarak işlenmesi
                        sebebiyle zarar uğramanız halinde zararın giderilmesini
                        talep etme
                    </li>
                    <li style={{textAlign:"justify"}} >
                        Kişisel verilerinizin toplanmasına, kullanımına ve
                        işlenmesine rıza göstermek veya bu rızanızı geri çekmek
                    </li>
                </ul>
                <p style={{textAlign:"justify"}}>
                    Kişisel verilerinizin işlenmesine ilişkin KVKK kapsamında
                    haklarınızı kullanmak için, Birinci Fatura ile iletişime
                    geçebilirsiniz. Ayrıca, KVKK kapsamında şikayetlerinizi ve
                    taleplerinizi Kişisel Verileri Koruma Kurumu'na
                    iletebilirsiniz.
                </p>
                <p style={{textAlign:"justify"}}>
  Bu KVKK Rıza Metni'ni okuduğunuzu ve Birinci Fatura tarafından işlenen kişisel verilerinizi toplama, kullanma ve işleme yöntemlerini ve koşullarını anladığınızı ve bu koşulları 
  <strong> kabul ettiğinizi</strong> beyan edersiniz.
</p>

            </div>
            </section>

        </ContentContainer>
    );
}
