import React from 'react'
import './NotFound.css'

function NotFound() {
  return (
    <div className='NotFoundPage'>
        <div style={{display:"flex" , alignContent:"center",flexDirection:"column" ,justifyContent:"center",alignItems:"center"}}>
        <h2 style={{fontSize:"3em",color:"white"}}>404 Sayfa Bulunamadı</h2>
        <p style={{fontSize:"1.2em",color:"#dddddd"}}>Üzgünüz, aradığınız sayfa bulunamadı.</p>
        <p style={{fontSize:"1.4em" }}><a className='a' href="/">Ana Sayfaya Dön</a></p>
        </div>
    </div>  )
}

export default NotFound;