import React from 'react';
import { Bars } from 'react-loader-spinner';

function Loading() {
    return (
        <div
            style={{
                display: 'flex',
                alignContent: 'center',
                height: '100vh', // Ekranın yüksekliği kadar genişlik
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                backgroundColor: '#0e1d56',
            }}
        >
            <div style={{
                display:"flex",
                flexDirection:"column" ,  
                          alignContent: 'center',
                alignItems: 'center',
              //  justifyContent: 'center',
                }}>
            <Bars
                height="130"
                width="130"
                color="#dcdcdc"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <h2 style={{color:"#dcdcdc",paddingTop:"1em"}}>Yükleniyor...</h2>

            </div>
        </div>
    );
}

export default Loading;
