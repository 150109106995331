import React from "react";
import styled from "styled-components";

import LogoImg from "../../images/logos/logo.png";
import { Link } from "react-router-dom";

const BrandLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.div`
  width: ${({ size }) => (size ? size + "px" : "2em")};
  height: ${({ size }) => (size ? size + "px" : "2em")};

  img {
    width: 100%;
    height: 100%;
  }
`;

const LogoTitleh2 = styled.h2`
  margin: 0;
  font-size: ${({ size }) => (size ? size + "px" : "20px")};
  color: ${({ color }) => (color ? color : "#fff")};
  font-weight: 900;
  margin-left: 6px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  margin-left: -3.75%;
`;

const LogoTitle = styled.h1`
  margin: 0;
  font-size: ${({ size }) => (size ? size + "px" : "20px")};
  color: ${({ color }) => (color ? color : "#fff")};
  font-weight: 900;
  margin-left: 6px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  margin-left: -3.75%;
`;

// const StyledLink = styled(Link)`
//   text-decoration: none;
// `;

export function BrandLogo(props) {
  const { logoSize, textSize, color, hideLogo ,title} = props;

  return (
    <BrandLogoContainer>
      {!hideLogo && (
        <Link to="/">
          <LogoImage size={logoSize}>
            <img src={LogoImg} alt="Servycing logo" />
          </LogoImage>
        </Link>
      )}
        <LogoTitleh2 size={textSize} color={color}>
        {title ? title : "Birinci Fatura"}  
        </LogoTitleh2>
    </BrandLogoContainer>
  );
}

export function BrandLogoTitle(props) {
  const { logoSize, textSize, color, hideLogo ,title} = props;

  return (
    <BrandLogoContainer>
      {!hideLogo && (
        <Link to="/">
          <LogoImage size={logoSize}>
            <img src={LogoImg} alt="Servycing logo" />
          </LogoImage>
        </Link>
      )}
        <LogoTitle size={textSize} color={color}>
        {title ? title : "Birinci Fatura"}  
        </LogoTitle>
    </BrandLogoContainer>
  );
}

