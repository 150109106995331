import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://dev.birincifatura.com',
  withCredentials: false
});

export default axiosInstance;

export const login = ({ username, password }) =>
axiosInstance({
    method: 'POST',
    url: '/api/app/fatura/web-token',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: `username=${username}&password=${password}&secretKey=SDA131.<Xk)(!'Q1@.as`,
  }).then(({ data }) => {
    return data;
  }).catch((reasons)=>{
    return reasons?.response?.data?.error?.message;
  });

export const Logout = ({ token }) => {
    axiosInstance({
    method: 'GET',
    url: `/api/app/fatura/logout/?Token=${token}`,
  }).then(({ data }) => {
    return data;
  });
};


export const DeleteUserDemand = ({ token }) => {
    axiosInstance({
    method: 'post',
    url: `/api/app/support-demand/delete-user-demand?Token=${token}`,
  }).then(({ data }) => {
    return data;
  });
};


export function getUserWelcome(token) {
    return axiosInstance.get(`/api/app/fatura/get-user-welcome?Token=${token}`).then(({ data }) => {
      return data.data;
    });
  }