import React from 'react';
import { deviceSize } from '../../components/responsive';
import SwiperCore, {
    Navigation,
    Pagination,
    Thumbs,
    Autoplay,
    FreeMode,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import VisibilitySensor from 'react-visibility-sensor';

import 'swiper/css';
import 'swiper/css/free-mode';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cards';
import 'swiper/css/thumbs';

import { useMediaQuery } from 'react-responsive';

import login from '../../images/TutorialImage/login.png';
import müsteri from '../../images/TutorialImage/müsteri.png';
import faturabilgileri from '../../images/TutorialImage/faturabilgileri.png';
import malveHizmet from '../../images/TutorialImage/malveHizmet.png';
import toplamalar from '../../images/TutorialImage/toplamlar.png';
import { motion } from 'framer-motion';

import './userManuel.css';
import { useRef, useState, useEffect } from 'react';

SwiperCore.use([Autoplay, Pagination]);

export default function UserManuel(props) {
    const isTablet = useMediaQuery({ maxWidth: deviceSize.tablet });

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const swiperRef = useRef(null);

    const stopButton = useRef(null);
    const startButton = useRef(null);

    const [isVisible, setIsVisible] = useState(false);




    useEffect(() => {
        if (isVisible) {
            startButton.current.click();
        }
        //  else {
        //     if (swiperRef.current.swiper.autoplay.running ) {
        //         stopButton.current.click();
        //     }

        // }

        return () => {
            stopButton.current && stopButton.current.click();
        };
    }, [isVisible, stopButton, startButton]);

    function onChange(isVisible) {
        console.log('Visibility');
        console.log(isVisible);

        setIsVisible(isVisible);
    }

    return (
        <>
            <section className="easy-invoice">
                <motion.div
                    whileInView={{
                        x: 0,
                        rotate: 0,
                        opacity: 1,
                        y: 0,
                    }}
                    initial={false}
                    animate={
                        isTablet ? { x: 0, opacity: 0 } : { y: 100, opacity: 0 }
                    }
                    transition={{
                        ease: 'easeOut',
                        duration: 1.0,
                    }}
                >
                    <div
                        className="containerswipper"
                        style={
                            isTablet
                                ? {
                                      maxWidth: window.innerWidth,
                                  }
                                : {
                                      maxWidth: window.innerWidth - 150,
                                  }
                        }
                    >
                        <div
                            style={
                                isTablet
                                    ? {
                                          flexDirection: 'column',
                                          maxWidth: window.innerWidth,
                                      }
                                    : {
                                          flexDirection: 'row',
                                          maxWidth: window.innerWidth - 150,
                                      }
                            }
                            className="row"
                        >
                            <div className="col-lg-4 offset-lg-2">
                                <div className="easy-invoice-nav-block">
                                    <VisibilitySensor onChange={onChange}>
                                        <div
                                            className="easy-invoice-nav-block-title fadeInUp-scroll visible"
                                            data-delay="100"
                                        >
                                            <h3>
                                                Faturalarını Kolayca oluştur
                                            </h3>
                                        </div>
                                    </VisibilitySensor>

                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={10}
                                        slidesPerView={isTablet ? 1 : 5}
                                        freeMode={false}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper"
                                        direction={
                                            isTablet ? 'horizontal' : 'vertical'
                                        }
                                    >
                                        <SwiperSlide
                                            style={{ height: '569px' }}
                                        >
                                            {({ isActive }) => (
                                                <div className="swiper-slide-content">
                                                    <div className="swiper-slide-content-title">
                                                        <h3>
                                                            Birinci E-Arşiv
                                                            Faturayı indir
                                                        </h3>
                                                    </div>
                                                    <div className="swiper-slide-content-bar">
                                                        <span className="video-story__progress">
                                                            <span className="video-story__progress-track"></span>
                                                            <span
                                                                className="video-story__progress-bar"
                                                                style={{
                                                                    transition:
                                                                        'none !important',
                                                                    transform:
                                                                        'scale(1, 0) translate(-50%, 0) !important',
                                                                }}
                                                            ></span>
                                                            <span className="video-story__progress-bullet"></span>
                                                        </span>
                                                    </div>
                                                    <div className="swiper-slide-content-description">
                                                        <div className="video-story__chapter-description">
                                                            <div className="video-story__chapter-description-content">
                                                                <p>
                                                                    Gelir
                                                                    İdaresi
                                                                    başkanlığı
                                                                    portal
                                                                    Kullanıcı
                                                                    kodu ve
                                                                    şifresi ile
                                                                    giriş yap.
                                                                </p>
                                                                <p>
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 400,
                                                                        }}
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </p>
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                        <SwiperSlide
                                            style={{ height: '569px' }}
                                        >
                                            <div className="swiper-slide-content">
                                                <div className="swiper-slide-content-title">
                                                    <h3>
                                                        Fatura oluşturmaya tıkla
                                                    </h3>
                                                </div>
                                                <div className="swiper-slide-content-bar">
                                                    <span className="video-story__progress">
                                                        <span className="video-story__progress-track"></span>
                                                        <span
                                                            className="video-story__progress-bar"
                                                            style={{
                                                                transition:
                                                                    'none !important',
                                                                transform:
                                                                    'scale(1, 0) translate(-50%, 0) !important',
                                                            }}
                                                        ></span>
                                                        <span className="video-story__progress-bullet"></span>
                                                    </span>
                                                </div>
                                                <div className="swiper-slide-content-description">
                                                    <div className="video-story__chapter-description">
                                                        <div className="video-story__chapter-description-content">
                                                            <div className="video-story__chapter-description">
                                                                <div className="video-story__chapter-description-content">
                                                                    <p>
                                                                        Müşteri
                                                                        bligilerini
                                                                        gir veya
                                                                        vkn/tckn
                                                                        numarası
                                                                        ile
                                                                        sistemden
                                                                        müşteri
                                                                        bilgilerine
                                                                        ulaş.
                                                                    </p>
                                                                    <p>
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide
                                            style={{ height: '569px' }}
                                        >
                                            <div className="swiper-slide-content">
                                                <div className="swiper-slide-content-title">
                                                    <h3>
                                                        Fatura bilgilerini
                                                        ayarla
                                                    </h3>
                                                </div>
                                                <div className="swiper-slide-content-bar">
                                                    <span className="video-story__progress">
                                                        <span className="video-story__progress-track"></span>
                                                        <span
                                                            className="video-story__progress-bar"
                                                            style={{
                                                                transition:
                                                                    'none !important',
                                                                transform:
                                                                    'scale(1, 0) translate(-50%, 0) !important',
                                                            }}
                                                        ></span>
                                                        <span className="video-story__progress-bullet"></span>
                                                    </span>
                                                </div>
                                                <div className="swiper-slide-content-description">
                                                    <div className="video-story__chapter-description">
                                                        <div className="video-story__chapter-description-content">
                                                            <div className="video-story__chapter-description">
                                                                <div className="video-story__chapter-description-content">
                                                                    <p>
                                                                        Kayıtlı
                                                                        notlardan
                                                                        daha
                                                                        önceki
                                                                        notlarınızı
                                                                        ekleyebilrisiniz.Düzenleme
                                                                        tarihini
                                                                        ayarla.
                                                                    </p>
                                                                    <p>
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                    <p></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide
                                            style={{ height: '569px' }}
                                        >
                                            <div className="swiper-slide-content">
                                                <div className="swiper-slide-content-title">
                                                    <h3>
                                                        Mal veya hizmetlerini
                                                        ekle
                                                    </h3>
                                                </div>
                                                <div className="swiper-slide-content-bar">
                                                    <span className="video-story__progress">
                                                        <span className="video-story__progress-track"></span>
                                                        <span
                                                            className="video-story__progress-bar"
                                                            style={{
                                                                transition:
                                                                    'none !important',
                                                                transform:
                                                                    'scale(1, 0) translate(-50%, 0) !important',
                                                            }}
                                                        ></span>
                                                        <span className="video-story__progress-bullet"></span>
                                                    </span>
                                                </div>
                                                <div className="swiper-slide-content-description">
                                                    <div className="video-story__chapter-description">
                                                        <div className="video-story__chapter-description-content">
                                                            <div className="video-story__chapter-description">
                                                                <div className="video-story__chapter-description-content">
                                                                    <p>
                                                                        Mal veya
                                                                        hizmetlerini
                                                                        ekle
                                                                    </p>
                                                                    <p>
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </p>
                                                                    <p
                                                                        style={{
                                                                            height: 14,
                                                                        }}
                                                                    ></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide
                                            style={{ height: '569px' }}
                                        >
                                            <div className="swiper-slide-content">
                                                <div className="swiper-slide-content-title">
                                                    <h3>Faturayı Oluştur</h3>
                                                </div>
                                                <div className="swiper-slide-content-bar">
                                                    <span className="video-story__progress">
                                                        <span
                                                            className="video-story__progress-bar"
                                                            style={{
                                                                transition:
                                                                    'none !important',
                                                                transform:
                                                                    'scale(1, 0) translate(-50%, 0) !important',
                                                            }}
                                                        ></span>
                                                        <span className="video-story__progress-bullet"></span>
                                                    </span>
                                                </div>
                                                <div className="swiper-slide-content-description">
                                                    <div className="video-story__chapter-description">
                                                        <div className="video-story__chapter-description-content">
                                                            <div className="video-story__chapter-description-content">
                                                                <p>
                                                                    Ön
                                                                    izlemenizi
                                                                    yapıp ,
                                                                    faturanızı
                                                                    oluşturabilirisiniz.
                                                                </p>
                                                                <p>
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 400,
                                                                        }}
                                                                    >
                                                                        &nbsp;
                                                                    </span>
                                                                </p>
                                                                <p></p>
                                                                <p
                                                                    style={{
                                                                        height: 14,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>

                            <div
                                className="col-lg-4 offset-lg-1 col-xl-3 offset-l-1 scale-scroll visible "
                                data-delay="350"
                                style={{
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                }}
                            >
                                <Swiper
                                    ref={swiperRef}
                                    style={{
                                        '--swiper-navigation-color': '#fff',
                                        '--swiper-pagination-color': '#fff',
                                    }}
                                    slidesPerView={1}
                                    
                                    autoplay={
                                        isVisible ? 
                                        {
                                        delay: 5000,
                                        stopOnLastSlide: true,
                                        // disableOnInteraction:false,
                                        // pauseOnMouseEnter :true,
                                        // waitForTransition : true
                                    } : false}
                                    spaceBetween={10}
                                    navigation={false}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[
                                        FreeMode,
                                        Navigation,
                                        Thumbs,
                                        Autoplay,
                                    ]}
                                    className="mySwiper2"
                                >
                                    <SwiperSlide
                                        style={
                                            isTablet
                                                ? {
                                                      marginLeft: 'auto',
                                                      marginRight: 'auto',
                                                  }
                                                : null
                                        }
                                    >
                                        <div>
                                            <img
                                                style={
                                                    isTablet
                                                        ? {
                                                              paddingLeft:
                                                                  '95px',
                                                              paddingRight:
                                                                  '95px',
                                                          }
                                                        : null
                                                }
                                                src={login}
                                                alt="My SVG"
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide
                                        style={
                                            isTablet
                                                ? {
                                                      marginLeft: 'auto',
                                                      marginRight: 'auto',
                                                  }
                                                : null
                                        }
                                    >
                                        <div>
                                            <img
                                                style={
                                                    isTablet
                                                        ? {
                                                              paddingLeft:
                                                                  '95px',
                                                              paddingRight:
                                                                  '95px',
                                                          }
                                                        : null
                                                }
                                                src={müsteri}
                                                alt="My SVG"
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide
                                        style={
                                            isTablet
                                                ? {
                                                      marginLeft: 'auto',
                                                      marginRight: 'auto',
                                                  }
                                                : null
                                        }
                                    >
                                        <div>
                                            <img
                                                style={
                                                    isTablet
                                                        ? {
                                                              paddingLeft:
                                                                  '95px',
                                                              paddingRight:
                                                                  '95px',
                                                          }
                                                        : null
                                                }
                                                src={faturabilgileri}
                                                alt="My SVG"
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide
                                        style={
                                            isTablet
                                                ? {
                                                      marginLeft: 'auto',
                                                      marginRight: 'auto',
                                                  }
                                                : null
                                        }
                                    >
                                        <div>
                                            <img
                                                style={
                                                    isTablet
                                                        ? {
                                                              paddingLeft:
                                                                  '95px',
                                                              paddingRight:
                                                                  '95px',
                                                          }
                                                        : null
                                                }
                                                src={malveHizmet}
                                                alt="My SVG"
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide
                                        style={
                                            isTablet
                                                ? {
                                                      marginLeft: 'auto',
                                                      marginRight: 'auto',
                                                  }
                                                : null
                                        }
                                    >
                                        <div>
                                            <img
                                                style={
                                                    isTablet
                                                        ? {
                                                              paddingLeft:
                                                                  '95px',
                                                              paddingRight:
                                                                  '95px',
                                                          }
                                                        : null
                                                }
                                                src={toplamalar}
                                                alt="My SVG"
                                            />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </motion.div>

                <div style={{display:"none"}}>
                    <button
                        ref={startButton}
                        style={{ height: '50px', width: '50px' }}
                        onClick={() =>
                            swiperRef.current.swiper.autoplay.start()
                        }
                    >
                        Start
                    </button>
                    <button
                        ref={stopButton}
                        style={{ height: '50px', width: '50px' }}
                        onClick={() => swiperRef.current.swiper.autoplay.stop()}
                    >
                        Stop
                    </button>
                </div>
            </section>
        </>
    );
}
