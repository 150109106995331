import React from 'react';
import styled from 'styled-components';
import  Footer  from '../../components/footer';
import  Navbar  from '../../components/navbar';
import PageContainer , {
    InnerPageContainer,
    
} from '../../components/pageContainer';
import { deviceSize } from '../../components/responsive';
import { blogPosts } from './BlogPage';

import './Blog.css';

const ContentContainer = styled.div`
    width: 100%;
    // height: 800px;
    // max-width: ${deviceSize.laptop}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
    background-color: aliceblue;

    @media screen and (max-width: ${deviceSize.mobile}px) {
        padding: 5px;
    }
`;
export default function BlogPageDetail({ match }) {
    const { id } = match.params;
    const data = blogPosts.find((x) => x.id == id);

    return (
        <PageContainer>
            <Navbar />
            <InnerPageContainer>
                <ContentContainer>
                    <section className="blogDetail">
                        {data.object}
                     
                    </section>
                </ContentContainer>
            </InnerPageContainer>
            <Footer />
        </PageContainer>
    );
}
