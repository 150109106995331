import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DeleteUserDemand, Logout, getUserWelcome, login } from '../../Api/DeleteUserApi';
const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
`;

const LoginContainer = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-sizing: border-box;
`;

const HomeContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-sizing: border-box;
  position: relative;
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupInner = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
`;

const SubTitle = styled.h3`
  margin-bottom: 20px;
  color: #333;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px 5px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  background-color: ${props => props.color || '#007bff'};

  &:hover {
    background-color: ${props => props.hoverColor || '#0056b3'};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
`;

const UserInfo = styled.div`
  text-align: left;
`;

const LogoutButton = styled(Button)`
  background-color: #dc3545;
  &:hover {
    background-color: #c82333;
  }
`;
export function UserDataDeletion() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token'));


  return (
    <AppContainer>
      {isLoggedIn ? <HomePage setIsLoggedIn={setIsLoggedIn} /> : <LoginPage setIsLoggedIn={setIsLoggedIn} />}
    </AppContainer>
  );
}

function LoginPage({ setIsLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    login({ username, password })
    .then(async data => {
      console.log(data);
      if (data?.isRegister) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('ticket',data.ticket);
        setIsLoggedIn(true);
      } else {
        alert('Kullanıcı sistemize kayıtlı değil');
      }
      return data;
    }).catch(err =>     alert(err)  );

  };

  return (
    <LoginContainer>
      <Title>Kullanıcı Verilerini Silmek İçin</Title>
      <Title>Giriş Yap</Title>
      <Input type="text" placeholder="Gib Kullanıcı Adı" value={username} onChange={(e) => setUsername(e.target.value)} />
      <Input type="password" placeholder="Gib Kullanıcı Şifresi" value={password} onChange={(e) => setPassword(e.target.value)} />
      <Button onClick={handleLogin}>Giriş Yap</Button>
    </LoginContainer>
  );
}

function HomePage({ setIsLoggedIn }) {
  const [showPopup, setShowPopup] = useState(false);
  const username = 'user'; // Placeholder username
  const ticketCount = 5; // Placeholder ticket count

  const [userInfo,setUserInfo] = useState({});

  const handleDeleteData = async () => {

    setShowPopup(true);
   
  };

  const handleLogout = async () => {
    var token = localStorage.getItem("token");
    var res = await Logout({token : token});
    localStorage.removeItem('token');
    localStorage.removeItem('ticket');

    setIsLoggedIn(false);
  };

  useEffect(()=>{
    var token = localStorage.getItem("token");

    getUserWelcome(token).then(data => { 
      setUserInfo(data);
    });
    
  },[])

  return (
    <HomeContainer>
      <Header>
        <Button onClick={handleLogout}>Güvenli Çıkış Yap</Button>
      </Header>
      <UserInfo>
          <div>Kullanıcı Id : {userInfo.userId}</div>
          <div>Kullanıcı Adı : {userInfo.userName}</div>
          <div>{`Bilet Sayısı: ${localStorage.getItem("ticket")}`}</div>
        </UserInfo>
      <Button onClick={handleDeleteData}>Verilerimi Sil</Button>
      {showPopup && <DeleteDataPopup setShowPopup={setShowPopup} setIsLoggedIn = {setIsLoggedIn} />}
    </HomeContainer>
  );
}

function DeleteDataPopup({ setShowPopup ,setIsLoggedIn}) {
  const handleConfirmDelete = async () => {
    var token = localStorage.getItem("token");

    var res1 = await DeleteUserDemand({token : token})

    console.log(res1);

    var res = await Logout({token : token});
    localStorage.removeItem('token');
    localStorage.removeItem('ticket');

    setIsLoggedIn(false);

    setShowPopup(false);
    alert('Talebiniz aktarılmıştır. En kısa sürede verileriniz silinecektir.');
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  return (
    <Popup>
      <PopupInner>
        <Title>Bütün verileriniz silinecek. Onaylıyor musunuz?</Title>
        <Button style={{backgroundColor:"red"}} onClick={handleConfirmDelete}>Evet</Button>
        <Button  onClick={handleCancel}>Hayır</Button>
      </PopupInner>
    </Popup>
  );
}
